@import "node_modules/mathsass/dist/math";

// Default styles will be mobile

// Set our media queries here
$mobile-width: 480px;
$big-mobile-width: 640px;
$tablet-width: 768px;
$desktop-width: 992px;
$big-screen-width: 1360px;

// Random breakpoints
$hero-break-mobile: 900px;

@mixin custom($width) {
  @media screen and (min-width: #{$width}) {
    @content;
  }
}

// Styles for larger mobile devices
@mixin mobile {
  @media screen and (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin big_mobile {
  @media screen and (min-width: #{$big-mobile-width}) {
    @content;
  }
}

// Styles for tablets
@mixin tablet {
  @media screen and (min-width: #{$tablet-width}) {
    @content;
  }
}

// Styles for desktops
@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

// Styles for desktops
@mixin big_screen {
  @media screen and (min-width: #{$big_screen-width}) {
    @content;
  }
}

// Use the styles below to target device range specifically without affecting
// other devices

// Styles for only smaller mobile devices
@mixin only_mobile {
  @media screen and (max-width: #{$big-mobile-width - 1px}) {
    @content;
  }
}

// Styles for only larger mobile devies
@mixin only_big_mobile {
  @media screen and (min-width: #{$big-mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Styles for everything smaller than an iPad
@mixin only_all_mobile {
  @media screen and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Styles for only tablet
@mixin only_tablet {
  @media screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin smaller_than_desktop {
  @media screen and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// Styles for only desktops
@mixin only_desktop {
  @media screen and (min-width: #{$desktop-width}) and (max-width: #{$big-screen-width - 1px}) {
    @content;
  }
}

// Styles for tablet landscape
@mixin tablet_landscape {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: landscape) {
    @content;
  }
}
