@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.Panel {
  background-color: map-get($map: $colors-background, $key: white);
  border-radius: map-get($map: $border-radius, $key: large);
  display: inline-block;
  padding: map-get($map: $padding, $key: xxlarge);
}
