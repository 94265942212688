@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$back-arrow-width: 16px;

.Button {
  cursor: pointer;
  padding: map-get($map: $padding, $key: large);
  position: relative;
}

.Text {
  color: map-get($map: $colors-text, $key: light);
  display: block;
  margin-left: $back-arrow-width;
  transition: color map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);
}

.Arrow {
  fill: map-get($map: $colors-text, $key: light);
  left: map-get($map: $margin, $key: medium);
  position: absolute;
  top: calc(50% - (#{$back-arrow-width} / 2));
  transition: transform map-get($map: $transition-duration, $key: fast)
      map-get($map: $transition-easing, $key: easeinout),
    fill map-get($map: $transition-duration, $key: fast)
      map-get($map: $transition-easing, $key: easeinout);
  width: $back-arrow-width;
}

.Button:hover {
  .Text {
    color: map-get($map: $colors-text, $key: default);
  }

  .Arrow {
    fill: map-get($map: $colors-text, $key: default);
    transform: translateX(-#{map-get($map: $margin, $key: small)});
  }
}
