@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.AuthSidebar {
  display: none;
  padding: map-get($map: $padding, $key: xxlarge) 50px;

  @include desktop {
    display: flex;
    height: 100%;
  }
}

.variant-primary {
  background: map-get($map: $colors-background, $key: dark-orange-gradient);
}

.variant-secondary {
  background: map-get($map: $colors-background, $key: blue-gradient);
}

.AgilityLogo {
  display: block;
  flex-grow: 0;
  margin-bottom: map-get($map: $margin, $key: xxlarge);
  width: 200px;
}

.AuthSidebarTagline {
  color: map-get($map: $colors-text, $key: white);
  font-size: 36px;
}

.AuthSidebarImage {
  width: 100%;

  img {
    width: 100%;
  }
}
