@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$max-page-width: 1200px;

.HeroContent {
  margin: (map-get($map: $margin, $key: xxlarge) * 2) auto 0;
  max-width: 1200px;
  height: 100%;
}

h1.HeroHeading {
  color: map-get($map: $colors-text, $key: white);
  font-size: 32px;
  margin: auto;
  text-align: center;

  @include big_mobile {
    font-size: 60px;
  }
}

.content {
  margin: 0 auto;
  max-width: 800px;
  padding: map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xlarge);
}

.faqSection {
  margin-bottom: map-get($map: $margin, $key: xxlarge) * 3;
}

.title {
  @include responsive_font(32px, 48px);
  color: map-get($map: $colors-text, $key: purple);
  font-weight: 700;
  text-align: center;
}

.question {
  @extend .title;
  @include responsive_font(18px, 24px);
  text-align: left;
  margin: 0;
  margin-top: map-get($map: $margin, $key: xxlarge) * 1.5;
}

.answer {
  @include responsive_font(15px, 21px);
  line-height: 1.2;

  li {
    margin-bottom: map-get($map: $margin, $key: xxlarge) * 1;
  }
}
