@import "~css/animations";
@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.JobsListItem {
  align-items: center;
  background-color: map-get($map: $colors-background, $key: white);
  border-radius: map-get($map: $border-radius, $key: medium);
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: 0 0 map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: xlarge)
    map-get($map: $padding, $key: xlarge);
  transition: box-shadow 0.1s ease-in-out;
  width: 100%;

  @include big_mobile {
    grid-column-gap: map-get($map: $margin, $key: xlarge);
    grid-template-columns: 1fr 160px;
    padding: map-get($map: $padding, $key: xxlarge);
  }

  &:not(.DemoMode):hover {
    box-shadow: $panel-raised-shadow;
    cursor: pointer;
  }

  &.DemoMode {
    user-select: none;
  }
}

.BasicInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.Link {
  align-items: center;
  display: flex;
}

p.LinkText {
  @include responsive_font(22px, 24px);

  color: map-get($map: $colors-text, $key: heading);
  font-weight: bold;
  line-height: 130%;
  margin: 0 0 4px;
  transition: color $fast;
}

.PostedDate {
  @include responsive_font(13px, 15px);

  color: map-get($map: $colors-text, $key: light);
  grid-row: 3;
}

.Reward {
  @include responsive_font(
    map-get($font-sizes, large),
    map-get($font-sizes, xlarge)
  );

  background-color: map-get($map: $colors-background, $key: light-green);
  border-radius: map-get($map: $border-radius, $key: medium);
  color: map-get($map: $colors-text, $key: green);
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  padding: map-get($map: $padding, $key: large) 0;
  text-align: center;
  width: 100%;

  @include big_mobile {
    font-size: map-get($font-sizes, xxlarge);
    grid-column: 2;
    padding: map-get($map: $padding, $key: large) + 7px 0;
  }
}

.RewardText {
  @include responsive_font(
    map-get($font-sizes, small),
    map-get($font-sizes, medium)
  );

  display: block;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 140%;
}

.Tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: map-get($map: $margin, $key: xlarge) - 4px 0;
}

.Tag {
  @include responsive_font(
    map-get($font-sizes, small) - 1px,
    map-get($font-sizes, small)
  );

  background-color: map-get($map: $colors-background, $key: very-light-blue);
  border-radius: map-get($map: $border-radius, $key: medium) - 3px;
  color: map-get($map: $colors-text, $key: link);
  font-weight: 600;
  letter-spacing: 0.25px;
  margin: 0 map-get($map: $margin, $key: default)
    map-get($map: $margin, $key: default) 0;
  padding: map-get($map: $padding, $key: small)
    map-get($map: $padding, $key: default);
}

.JobsListItem.Loading {
  background-color: map-get($map: $colors-background, $key: white);

  .LoadingItem {
    align-items: center;
    border-left: 0;
    color: rgba(255, 255, 255, 0);
    display: flex;
    overflow: hidden;
    position: relative;

    &::before {
      background: map-get($map: $colors-background, $key: loading);
      border: 0;
      content: "";
      display: block;
      height: 90%;
      position: absolute;
      width: 100%;
    }
  }

  .Link {
    @include responsive_font(18px, 22px);

    width: 250px;

    .LinkText {
      color: rgba(255, 255, 255, 0);
    }
  }

  .Detail {
    @include responsive_font(
      map-get($map: $font-sizes, $key: medium),
      map-get($map: $font-sizes, $key: medium)
    );

    width: 100px;

    &::after {
      visibility: hidden;
    }
  }

  .Tag {
    background-color: map-get($map: $colors-background, $key: white);
    width: 80px;
  }

  .PostedDate {
    @include responsive_font(13px, 15px);

    width: 120px;
  }

  .PositionType {
    width: 270px;

    @include tablet {
      margin: 0 60px;
      width: auto;
    }
  }

  .PositionsOpen {
    background-color: transparent;
    width: 160px;

    @include tablet {
      width: auto;

      &::before {
        width: 30px;
      }
    }
  }

  .Reward {
    width: 240px;

    @include tablet {
      background: none;
      height: calc(110px / 0.9);
      justify-content: right;
      margin-left: auto;
      width: 160px;
    }
  }
}
