@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.ProactiveReferralLink {
  background-color: map-get($map: $colors-background, $key: very-light-blue);
  border-radius: map-get($map: $border-radius, $key: large);
  display: grid;
  margin: 0 0 map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: xlarge)
    map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xxlarge);

  @include tablet {
    padding: map-get($map: $padding, $key: xlarge)
      map-get($map: $padding, $key: xxlarge);
  }

  @include tablet {
    grid-template-columns: auto 1fr auto;
  }
}

.NoReferralsImg {
  margin: auto auto map-get($map: $margin, $key: large);
  min-height: 92px; // this min-height is here to make the load-in less jumpy
  width: 118px;
}

.Text {
  margin: 0 auto map-get($map: $margin, $key: large);

  @include tablet {
    margin: auto 0 auto map-get($map: $margin, $key: xlarge);
  }
}

a.Link {
  background-color: map-get($map: $colors-background, $key: blue);
  border-radius: map-get($map: $border-radius, $key: medium);
  color: map-get($map: $colors-text, $key: white);
  font-weight: 600;
  margin: auto;
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xlarge);
  transition: background-color $fast;

  &:hover {
    background-color: darken(map-get($colors-background, blue), 5%);
  }
}
