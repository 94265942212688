@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$navlink-transition-easing: map-get(
    $map: $transition-duration,
    $key: fast,
  )
  map-get(
    $map: $transition-easing,
    $key: easeinout,
  );

.Wrapper {
  background: map-get($map: $colors-text, $key: white);
}

.AgilityLogoLink {
  grid-column: 1 / span 3;
  grid-row: 1 / 2;

  @include tablet {
    grid-column: 1;
  }
}

.Inner {
  align-items: center;
  display: grid;
  grid-template-columns: auto 100px $page-gutter-mobile;
  grid-template-rows: 60px 70px;
  margin: 0 auto;
  max-width: $page-max-width;
  padding: 0;
  position: relative;

  @include big_mobile {
    grid-template-columns: 1fr 1.4fr 1fr;
    grid-template-rows: auto;
    padding: map-get($map: $padding, $key: medium) $page-gutter-mobile;
  }

  @include tablet {
    grid-template-columns: 210px auto 220px;
  }

  @include big_screen {
    padding: map-get($map: $padding, $key: medium) $page-gutter;
  }

  &.InnerWithoutAuth {
    grid-template-columns: 1fr 200px 1fr;
    grid-template-rows: 1fr;

    .AgilityLogoLink {
      grid-column: 2;
      margin: 14px 0;

      @include big_mobile {
        margin: 23px 0;
      }
    }
  }
}

.AgilityLogo {
  display: block;
  flex-grow: 0;
  height: auto;
  margin: map-get($map: $padding, $key: medium) auto;
  width: 160px;

  @include big_mobile {
    margin: 0;
  }

  @include tablet {
    width: 200px;
  }
}

.NavLinks {
  border-top: 1px solid map-get($map: $colors-border, $key: container);
  display: flex;
  flex-grow: 1;
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  justify-content: center;
  padding: 8px 0 0 0;
  width: 100%;

  @include big_mobile {
    border-top: 0;
    grid-column: 2;
    grid-row: 1;
    padding: map-get($map: $padding, $key: xlarge);
    justify-content: flex-end;
  }

  &.NavLinks404 {
    visibility: hidden;
  }
}

.NavLinksCenter {
  justify-content: center;

  @include big_mobile {
    justify-content: center;
  }
}

.NavLink {
  align-items: center;
  color: map-get($map: $colors-text, $key: light);
  display: flex;
  font-size: map-get($map: $font-sizes, $key: medium);
  font-weight: 500;
  justify-content: center;
  margin: 0;
  padding: map-get($map: $padding, $key: large);
  transition: color $navlink-transition-easing;
  width: 50%;

  @include big_mobile {
    justify-content: normal;
    margin: 0 map-get($map: $padding, $key: large);
    padding: 0;
    width: auto;
  }

  @include tablet {
    margin: 0 map-get($map: $padding, $key: xlarge);
    flex-direction: column;
  }

  svg {
    filter: contrast(0%);
    margin-right: map-get($map: $padding, $key: medium);
    opacity: 0.5;
    transition: filter $navlink-transition-easing,
      opacity $navlink-transition-easing;
    width: 30px;

    @include tablet {
      width: auto;
      margin-bottom: 7px;
      margin-right: 0;
    }
  }

  &:hover {
    color: map-get($map: $colors-text, $key: heading);

    svg {
      filter: contrast(100%);
      opacity: 1;
    }
  }

  &.isActive {
    border-bottom: 3px solid map-get($map: $colors-border, $key: orange);
    color: map-get($map: $colors-text, $key: heading);

    @include big_mobile {
      border-bottom: 0;
    }

    svg {
      filter: contrast(100%);
      opacity: 1;
    }
  }
}

.NavLinksSecondary {
  border-left: 1px solid map-get($map: $colors-border, $key: container);
  display: none;
  padding-left: map-get($map: $padding, $key: xlarge);
  margin-left: map-get($map: $margin, $key: xlarge);

  @include tablet {
    align-items: center;
    display: flex;
  }
}

.secondaryLink {
  color: map-get($map: $colors-text, $key: default);
  margin: auto map-get($map: $padding, $key: medium);

  &:hover {
    color: darken(map-get($map: $colors-text, $key: default), 30%);
  }
}

.LoginSignupWrapper {
  align-items: center;
  display: flex;
  flex-grow: 0;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  border-left: 1px solid map-get($map: $colors-border, $key: container);

  @include big_mobile {
    grid-column: 3;
    grid-row: 1;
    margin-left: auto;
    position: static;
    height: 60%;
  }
}

.LoginSignupButtonDropdown {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin-left: auto;
}

.LoginSignupButton {
  // padding: 0;
}

.Chevron {
  fill: map-get($map: $colors-text, $key: default);
  height: 12px;
  margin: map-get($map: $padding, $key: large);
  width: 12px;
}

.LogInLink {
  background-color: map-get($map: $colors-background, $key: orange);
  border-radius: map-get($map: $border-radius, $key: medium);
  color: map-get($map: $colors-text, $key: white);
  font-size: map-get($map: $font-sizes, $key: small);
  font-weight: bold;
  letter-spacing: 0.25px;
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xlarge);

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium);
  }

  &:hover {
    background-color: darken(
      map-get($map: $colors-background, $key: orange),
      5%
    );
  }
}

.SignUpLink {
  color: map-get($map: $colors-text, $key: default);
  font-size: map-get($map: $font-sizes, $key: small);
  margin-left: map-get($map: $padding, $key: medium);
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xlarge);

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium);
    margin-left: map-get($map: $padding, $key: large);
  }

  &:hover {
    border-color: darken(map-get($map: $colors-border, $key: container), 5%);
    color: darken(map-get($map: $colors-text, $key: default), 30%);
  }
}

.AccountBtnWrapper {
  flex-grow: 0;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin-left: auto;

  @include big_mobile {
    grid-column: 3;
    grid-row: 1;
    margin-top: 0;
    position: static;
  }
}

.AccountBtn {
  // not mobile first, but only wanted to overwrite Button's padding on mobile
  @include only_mobile {
    height: 40px;
    padding: 0;
    width: 40px;
  }
}

.AuthAccountBtnChildren {
  align-items: center;
  display: flex;
}

.AuthAccountIcon {
  fill: map-get($map: $colors-text, $key: light);
  margin: 0;
  width: 20px;

  @include big_mobile {
    margin-right: map-get($map: $margin, $key: large);
    width: 25px;
  }
}

.AuthAccountBtnChildrenText {
  display: none;

  @include big_mobile {
    display: block;
  }
}

.UnauthAccountBtnChildren {
  align-items: center;
  display: flex;
}

.UnauthAccountIcon {
  display: none;
  fill: map-get($map: $colors-text, $key: light);
  margin: 0;
  width: 20px;

  @include big_mobile {
    display: block;
    margin-right: map-get($map: $margin, $key: large);
    width: 25px;
  }
}

.SubNavWrapper {
  background: map-get($map: $colors-background, $key: white);
  border-bottom: 1px solid map-get($map: $colors-border, $key: accent-gray);
  border-top: 1px solid map-get($map: $colors-border, $key: accent-gray);
  color: map-get($map: $colors-text, $key: primary);
  height: 60px;
  padding: 0 $page-gutter-mobile;
}

.SubNavInner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: $page-max-width;

  @include desktop {
    justify-content: start;
  }

  @include big_screen {
    padding: 0 $page-gutter;
  }
}
