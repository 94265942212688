@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.AuthLogoLinkMobile {
  display: block;
  margin-bottom: map-get($map: $margin, $key: xxlarge);

  @include desktop {
    display: none;
  }
}

.ShowLogo {
  @include desktop {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.AgilityLogo {
  display: block;
  flex-grow: 0;
  margin: 0 auto map-get($map: $margin, $key: xlarge) * 1.5;
  width: 240px;

  @include desktop {
    margin: map-get($map: $padding, $key: medium) auto
      map-get($map: $margin, $key: xlarge) * 2;
  }
}
