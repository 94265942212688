@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.ReferralsUnauthenticated {
  grid-column: 1 / 3;
  margin: auto;
  margin-top: map-get($map: $margin, $key: xxlarge);
  max-width: $page-max-width;
  text-align: center;
  width: 100%;
}

.heading {
  @include responsive_font_important(36px, 46px);

  margin-left: auto;
  margin-right: auto;
  max-width: 850px;
  text-align: center;
}

.text {
  margin: auto;
  max-width: 600px;
}

.links {
  display: flex;
  justify-content: center;
  margin: map-get($map: $margin, $key: xxlarge) * 1.5 0;
}

.signUp,
.logIn {
  border-radius: map-get($map: $border-radius, $key: medium);
  display: block;
  font-weight: 600;
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xlarge);
}

.signUp {
  background-color: map-get($map: $colors-background, $key: blue);
  border: 1px solid map-get($map: $colors-background, $key: blue);
  color: map-get($map: $colors-text, $key: white);
  margin-right: map-get($map: $margin, $key: xlarge);

  &:hover {
    background-color: darken(map-get($map: $colors-background, $key: blue), 5%);
  }
}

.logIn {
  background-color: map-get($map: $colors-background, $key: white);
  border: 1px solid map-get($map: $colors-border, $key: dark-gray);
  color: map-get($map: $colors-text, $key: light);

  &:hover {
    background-color: darken(
      map-get($map: $colors-background, $key: white),
      2.5%
    );
  }
}

.demoReferral {
  margin-left: auto;
  margin-right: auto;
  margin-top: map-get($map: $margin, $key: xxlarge);
  max-width: 900px;
  pointer-events: none;
  position: relative;
  text-align: left;
  user-select: none;
  width: 100%;

  &::after {
    background: linear-gradient(
      180deg,
      change-color(
          $color: map-get($map: $colors-background, $key: light-gray),
          $alpha: 0
        )
        10%,
      map-get($map: $colors-background, $key: light-gray) 70%
    );
    bottom: 0;
    content: "";
    height: 150px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
