@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.FileUpload {
  margin: map-get($map: $margin, $key: xlarge) 0;
}

.Dropzone {
  align-content: center;
  align-items: center;
  background: #f8f9fa;
  border: 1.5px dashed #dcdcdc;
  border-radius: map-get($map: $border-radius, $key: large);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: map-get($map: $margin, $key: xlarge);
  margin-top: map-get($map: $margin, $key: default);
  max-height: 100px;
  padding: map-get($map: $padding, $key: xlarge) * 2
    map-get($map: $padding, $key: xlarge);
  transition: border-color 0.3s, background 0.3s, opacity 0.3s;

  svg {
    margin-right: map-get($map: $margin, $key: default);
  }

  p {
    @include responsive_font(14px, 14px);

    line-height: 120% !important;
    margin: 0;
  }
}

.DropzoneLightText {
  color: map-get($map: $colors-text, $key: light);
}

.FileUploaded {
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
  padding: map-get($map: $padding, $key: large) 0;

  svg {
    fill: map-get($map: $colors-text, $key: light);
    width: 12px;
  }

  button {
    padding: map-get($map: $padding, $key: medium);
  }
}

.dropzoneReject {
  border-color: map-get($map: $colors-background, $key: blue);
  opacity: 0.6;
}

.dropzoneError {
  border-color: map-get($map: $colors-background, $key: red);
  opacity: 0.6;
}
