@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.Content {
  margin: 0 auto;
  max-width: $page-max-width;
  padding: map-get($map: $padding, $key: xlarge)
    map-get($map: $padding, $key: large);

  @include big_mobile {
    display: grid;
    grid-template-columns: 49% 2% 49%;
    grid-template-rows: auto auto auto;
  }

  @include desktop {
    grid-template-columns: 64% 2% 34%;
    grid-template-rows: auto auto;
  }

  @include big_screen {
    padding-left: $page-gutter;
    padding-right: $page-gutter;
  }
}

.BackButtonLink {
  margin-bottom: map-get($map: $margin, $key: large);
  width: auto;

  @include big_mobile {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  @include desktop {
    justify-self: start;
  }
}

.ModalShareIllustration {
  display: block;
  margin: map-get($map: $margin, $key: medium) auto
    map-get($map: $margin, $key: xlarge);
}

.ModalJobName {
  margin-bottom: map-get($map: $margin, $key: xlarge);
  margin-top: 0;
}

.ModalRewardBox {
  background-color: map-get($map: $colors-background, $key: light-green);
  border-radius: map-get($map: $border-radius, $key: large);
  margin-bottom: map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: xlarge);
  text-align: center;

  p {
    line-height: map-get($map: $line-heights, $key: xlarge);
    margin: 0;
  }
}

.CopyLink {
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.ModalShareMessage {
  line-height: map-get($map: $line-heights, $key: large);
  margin: 0;

  > span {
    line-height: map-get($map: $line-heights, $key: large);
  }
}

.ModalShareButton,
%modal-share-button {
  margin-bottom: map-get($map: $margin, $key: xlarge);
  width: 100%;
}

.ModalShareSvg,
%modal-share-svg {
  height: 25px;
  margin-right: map-get($map: $margin, $key: medium);
}

.FacebookButton {
  @extend %modal-share-button;

  background-color: map-get($map: $colors-social, $key: facebook);
  border-color: transparent;
  color: map-get($map: $colors-text, $key: white);

  &:hover {
    background-color: darken(map-get($map: $colors-social, $key: facebook), 5%);
    border-color: transparent;
    color: map-get($map: $colors-text, $key: white);
  }
}

.FacebookSvg {
  @extend %modal-share-svg;

  fill: map-get($map: $colors-text, $key: white);
}

.LinkedInButton {
  @extend %modal-share-button;

  background-color: map-get($map: $colors-social, $key: linkedin);
  border-color: transparent;
  color: map-get($map: $colors-text, $key: white);

  &:hover {
    background-color: darken(map-get($map: $colors-social, $key: linkedin), 5%);
    border-color: transparent;
    color: map-get($map: $colors-text, $key: white);
  }
}

.LinkedInSvg {
  @extend %modal-share-svg;

  fill: map-get($map: $colors-text, $key: white);
}

.EmailButton {
  @extend %modal-share-button;

  margin-bottom: 0;
}

.EmailSvg {
  @extend %modal-share-svg;

  fill: map-get($map: $colors-text, $key: light);
}

.JobDetailsPanel {
  margin-bottom: map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: xlarge);
  width: 100%;

  @include big_mobile {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    padding: map-get($map: $padding, $key: xxlarge);
  }

  @include desktop {
    align-self: start;
    grid-column: 1 / 2;
    padding: map-get($map: $padding, $key: xxlarge);
  }
}

.ModalUnauthenticatedBox {
  background-color: map-get($map: $colors-background, $key: light-gray);
  border-radius: map-get($map: $border-radius, $key: large);
  padding: map-get($map: $padding, $key: xlarge);
  text-align: center;
}

.ModalSignupText {
  margin-bottom: 0;
  text-align: center;
}

h1.PageHeading {
  font-family: $body;
  font-size: 26px;
  font-weight: bold;
  line-height: 120%;
  margin: 0 0 8px;

  @include tablet {
    font-size: 28px;
  }

  @include desktop {
    font-size: 36px;
  }
}

.Details {
  display: flex;
  flex-direction: column;
  margin-top: map-get($map: $margin, $key: small);

  @include big_mobile {
    align-items: center;
    flex-direction: row;
  }

  &.LoadingItem {
    width: 50%;
  }
}

.BasePayHeading {
  display: block;
  font-size: map-get($font-sizes, small);
  margin-top: map-get($map: $margin, $key: large);
}

.LogInBanner {
  align-items: center;
  border: 1px solid map-get($map: $colors-border, $key: accent-gray);
  border-radius: map-get($map: $border-radius, $key: large);
  display: flex;
  font-weight: 700;
  margin-top: map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: small)
    map-get($map: $padding, $key: xlarge);
}

.Lock {
  fill: map-get($map: $colors-text, $key: default);
  margin-right: map-get($map: $margin, $key: large);
}

.Tags {
  composes: Tags from "../../components/JobsListItem/JobsListItem.module.scss";
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 0;

  &.LoadingItem {
    height: 50px;
  }
}

.Tag {
  composes: Tag from "../../components/JobsListItem/JobsListItem.module.scss";
  margin-bottom: map-get($map: $margin, $key: medium);
  margin-right: map-get($map: $margin, $key: medium);
  padding: map-get($map: $padding, $key: small)
    map-get($map: $padding, $key: default);
  width: auto;
}

.ShareBanner {
  background-color: map-get($map: $colors-background, $key: light-green);
  border-radius: map-get($map: $border-radius, $key: large);
  display: flex;
  flex-direction: column;
  margin: map-get($map: $margin, $key: xlarge) 0
    map-get($map: $margin, $key: xlarge) * 1.5 0;
  padding: map-get($map: $padding, $key: xlarge);

  @include big_mobile {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .ShareBannerText {
    margin-bottom: map-get($map: $margin, $key: large);
    text-align: center;

    @include big_mobile {
      margin-bottom: 0;
      text-align: left;
    }

    > span {
      font-size: map-get($font-sizes, medium);

      @include big_mobile {
        font-size: map-get($font-sizes, large);
      }
    }

    .ShareBannerNumber {
      font-size: map-get($font-sizes, large);
      margin-right: map-get($map: $margin, $key: medium);

      @include big_mobile {
        font-size: map-get($font-sizes, xlarge);
      }
    }
  }
}

.ShareButton {
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xlarge);
}

.DollarSign {
  fill: map-get($map: $colors-text, $key: white);
  margin-right: map-get($map: $margin, $key: small);
  width: 15px;
}

.SectionHeading {
  @include responsive_font(15px, 20px);

  font-family: $body;
  margin: map-get($map: $margin, $key: medium) - 2px 0;

  &.LoadingItem {
    margin-bottom: map-get($map: $margin, $key: large);
    margin-top: map-get($map: $margin, $key: xxlarge);
    width: 50%;
  }
}

.TextSection {
  line-height: 140%;
  padding-bottom: map-get($map: $margin, $key: medium);

  @include tablet {
    padding-bottom: map-get($map: $margin, $key: large);
  }

  * {
    background-color: transparent !important;
    font-family: $body !important;
    font-size: 15px !important;
    line-height: 150% !important;

    @include tablet {
      font-size: 16px !important;
    }
  }

  ul {
    margin-top: map-get($map: $margin, $key: large);
    padding-left: map-get($map: $padding, $key: xlarge);

    li {
      margin-bottom: map-get($map: $margin, $key: default);
      padding-left: 0;
    }
  }

  &.LoadingItem {
    margin-bottom: map-get($map: $margin, $key: medium);
  }
}

.ShareDetails {
  background-color: map-get($map: $colors-background, $key: light-green);
  border-radius: map-get($map: $border-radius, $key: medium);
  margin-top: map-get($map: $margin, $key: xxlarge);
  padding: map-get($map: $padding, $key: xxlarge);
}

.ShareDetailsGreen {
  @include responsive_font(18px, 24px);

  color: map-get($map: $colors-text, $key: green);
  font-weight: 700;
  text-align: center;
}

.ShareDetailsBlack {
  @include responsive_font(14px, 18px);

  color: map-get($map: $colors-text, $key: black);
  margin: 0 auto;
  max-width: 450px;
  opacity: 0.95;
  text-align: center;
}

.DetailShareButton {
  margin-bottom: map-get($map: $margin, $key: xlarge);
  margin-top: map-get($map: $margin, $key: xlarge);
  width: 100%;
}

.ReferralDisclaimerOne,
.ReferralDisclaimerTwo {
  line-height: 1.3;
  text-align: center;
}

.LoadingItem {
  align-items: center;
  color: rgba(255, 255, 255, 0);
  display: flex;
  overflow: hidden;
  position: relative;

  &::before {
    background: map-get($map: $colors-background, $key: loading);
    border: 0;
    border-radius: map-get($map: $border-radius, $key: small);
    content: "";
    display: block;
    height: 80%;
    position: absolute;
    width: 100%;
  }
}

.ReferPanel {
  background: none;
  padding: 0;
  width: 100%;

  @include big_mobile {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }

  @include desktop {
    align-self: start;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
}

.HorizontalStats {
  display: flex;
  flex-direction: row;
  margin-top: map-get($map: $margin, $key: medium);
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.HorizontalStat {
  margin-left: map-get($map: $margin, $key: xxlarge);
}
