@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.AuthButton,
%auth-button {
  box-shadow: map-get($map: $colors-shadow, $key: default);
  margin: map-get($map: $margin, $key: large) 0;
  padding: 0;
  transition: background-color
    map-get($map: $transition-duration, $key: default)
    map-get($map: $transition-easing, $key: easeinout);
  width: 100%;

  > svg {
    height: 26px;
    margin-right: map-get($map: $margin, $key: large);
    width: 26px;
  }
}

.SocialButtonIcon {
  align-items: center;
  border-right: 1px solid rgba(252, 253, 253, 0.2);
  display: flex;
  justify-content: center;
  padding: 10px;
}

.SocialButtonText {
  flex: 1;
  font-family: $body;
  font-weight: bold;
}

.GithubButton {
  @extend %auth-button;

  background-color: map-get($map: $colors-social, $key: github);
  color: map-get($map: $colors-text, $key: white);

  &:hover {
    background-color: darken(map-get($map: $colors-social, $key: github), 5%);
  }
}

.GithubIcon {
  fill: map-get($map: $colors-background, $key: white);
  height: 26px;
  width: 26px;
}

.LinkedinButton {
  @extend %auth-button;

  background-color: map-get($map: $colors-social, $key: linkedin);
  color: map-get($map: $colors-text, $key: white);

  &:hover {
    background-color: darken(map-get($map: $colors-social, $key: linkedin), 5%);
  }
}

.LinkedinIcon {
  fill: map-get($map: $colors-background, $key: white);
  height: 26px;
  width: 26px;
}

.FacebookButton {
  @extend %auth-button;

  background-color: map-get($map: $colors-social, $key: facebook);
  color: map-get($map: $colors-text, $key: white);

  &:hover {
    background-color: darken(map-get($map: $colors-social, $key: facebook), 5%);
  }
}

.FacebookIcon {
  fill: map-get($map: $colors-background, $key: white);
  height: 26px;
  width: 26px;
}
