@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.LoginWithEmail {
  margin-bottom: map-get($map: $margin, $key: xlarge);

  .EmailInput {
    margin-top: 0;
  }
}

.LogInButton {
  width: 100%;
}

.PasswordInput {
  margin-bottom: map-get($map: $margin, $key: medium);
}

.ForgotPasswordLink {
  display: inline-block;
  margin-bottom: map-get($map: $margin, $key: xlarge);
}
