@import "~css/layout";
@import "~css/media";

.Details {
  display: flex;
  flex-direction: column;
  margin-top: map-get($map: $margin, $key: small);

  @include big_mobile {
    align-items: center;
    flex-direction: row;
  }

  .Detail {
    align-items: center;
    display: flex;
    margin-bottom: map-get($map: $margin, $key: xsmall);
    margin-right: map-get($map: $margin, $key: large);

    svg {
      fill: currentColor;
      height: 15px;
      margin-right: map-get($map: $margin, $key: small);
      width: 15px;
      z-index: 0;

      path {
        fill: currentColor;
      }
    }
  }
}
