@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.Content {
  margin: $page-gutter-mobile auto;
  padding-bottom: map-get($map: $padding, $key: xxlarge) * 2;

  @include tablet {
    max-width: 800px;
    width: 80%;
  }

  @include big-screen {
    max-width: 1400px;
    padding: 0 $page-gutter-mobile;
  }

  @include desktop {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin: map-get($map: $margin, $key: xxlarge) auto
      map-get($map: $margin, $key: xxlarge);
  }

  @include big_screen {
    padding: 0 ($page-gutter - $page-gutter-mobile);
  }
}

.PageHeading {
  grid-row: 1;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.LogInBannerContainer {
  padding: 0 15px;
}

.LogInBanner {
  border: 1px solid map-get($map: $colors-border, $key: dark-gray);
  composes: LogInBanner from "../jobsDetail/JobsDetailPage.module.scss";
  margin-bottom: map-get($map: $margin, $key: xxlarge);

  @include desktop {
    margin-left: 0;
    margin-right: 0;
  }

  p {
    color: map-get($map: $colors-text, $key: heading);
  }
}

.Lock {
  fill: map-get($map: $colors-text, $key: default);
  margin-right: map-get($map: $margin, $key: large);
}

.FilterPanelWrapper {
  background-color: map-get($map: $colors-background, $key: light-gray);
  grid-column: 1;
  margin-top: map-get($map: $margin, $key: medium);
  padding: 7px 15px 0;
  position: sticky;
  top: 0;
  z-index: 1;
}
