@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.Heading {
  color: map-get($map: $colors-text, $key: heading);
  font-family: $heading;
  font-weight: 800;
}

h1.Heading {
  font-size: map-get($map: $font-sizes, $key: xlarge);
}

h2.Heading {
  font-size: map-get($map: $font-sizes, $key: large);
}

h3.Heading {
  font-size: map-get($map: $font-sizes, $key: medium);
}

h4.Heading {
  font-size: map-get($map: $font-sizes, $key: small);
}

// Alignment classes
.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

// Invert font and Background
.IsInverted {
  background-image: $purple-gradient;
  color: map-get($map: $colors-text, $key: white);
}
