@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.ReferralsList {
  padding: 0;
  width: 100%;

  section {
    margin: 0 0 map-get($map: $margin, $key: xlarge);
    width: 100%;
  }
}

.Spinner {
  margin-top: map-get($map: $margin, $key: xxlarge);
}
