@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/typography";

.Content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10%;

  @include tablet {
    padding: 0 $page-gutter;
  }

  .IllustrationContainer {
    margin-bottom: map-get($map: $margin, $key: default);
    max-width: 380px;
    width: 100%;

    svg {
      height: 220px;
      width: 100%;

      @include big_mobile {
        height: 260px;
      }

      @include tablet {
        height: 300px;
      }
    }
  }

  .Heading {
    margin-bottom: map-get($map: $margin, $key: large);
    margin-top: map-get($map: $margin, $key: small);

    @include big_mobile {
      margin-bottom: map-get($map: $margin, $key: xlarge);
    }
  }

  .Body {
    font-size: map-get($map: $font-sizes, $key: large);
    line-height: 140%;
    margin-bottom: map-get($map: $margin, $key: xlarge);
    margin-top: 0;
    text-align: center;
  }
}
