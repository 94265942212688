@import "~css/colors";
@import "~css/layout";

.SpinnerWrapper {
  margin-left: auto;
  margin-right: auto;
}

.Text {
  display: block;
  margin-top: map-get($map: $margin, $key: medium);
  text-align: center;
}

.Spinner {
  margin: 0 auto;
  position: relative;
}

.DoubleBounce1,
.DoubleBounce2 {
  animation: sk-bounce 2s infinite ease-in-out;
  background-color: darken(
    map-get($map: $colors-background, $key: accent-gray),
    10%
  );
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.DoubleBounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
