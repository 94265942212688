@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.ReferralsMoney {
  margin-bottom: map-get($map: $margin, $key: large);
  padding: map-get($map: $padding, $key: xlarge);
  text-align: center;

  @include desktop {
    text-align: right;
  }

  @include desktop {
    margin-bottom: 30px;
    padding: 0 map-get($map: $padding, $key: default);
    text-align: left;
  }
}

.StandToMakeHeading.StandToMakeHeading,
.MadeHeading.MadeHeading {
  color: map-get($map: $colors-text, $key: black);
  display: block;
  font-size: map-get($map: $font-sizes, $key: large);
  font-weight: 600;
  margin-bottom: map-get($map: $margin, $key: medium);
}

.Amount.Amount {
  display: block;
  font-size: map-get($map: $font-sizes, $key: xxlarge);
  font-weight: 600;
}

.ReferralsMoney.isLoading {
  .Amount.Amount {
    align-items: center;
    border-left: 0;
    color: rgba(255, 255, 255, 0);
    display: flex;
    overflow: hidden;
    position: relative;

    &::before {
      background: darken(map-get($map: $colors-background, $key: loading), 5%);
      border: 0;
      border-radius: map-get($map: $border-radius, $key: medium);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}
