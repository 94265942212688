@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.RadioGroup {
  align-items: flex-start;
  border: 0;
  display: flex;
  flex-direction: column;
  margin: 0 0 map-get($map: $margin, $key: xlarge);
  padding: 0;
}

.RadioGroup.Error {
  label {
    color: map-get($map: $colors-interactive, $key: error);
  }
}

.radioGroupLabel {
  margin-bottom: map-get($map: $margin, $key: default);
}

.radioOptions {
  display: flex;
}

.radioOption {
  align-items: center;
  display: flex;
  margin-right: map-get($map: $margin, $key: default);
}

.radioOptionLabel {
  font-size: map-get($map: $font-sizes, $key: medium);
  margin-left: map-get($map: $margin, $key: small);
}

.ErrorText {
  color: map-get($map: $colors-interactive, $key: error);
  font-size: map-get($map: $font-sizes, $key: small);
  margin-top: map-get($map: $margin, $key: medium);
}
