@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";

.Wrapper {
  display: flex;
  width: 100%;
}

.Wrapper .Input {
  flex-grow: 1;
  margin: 0;
}

.Wrapper .InputElement {
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top-right-radius: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  &.hasFooterSlot {
    border-bottom-left-radius: 0;
  }
}

.Button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  flex-grow: 0;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;

  @include only_mobile {
    min-width: 130px;
    padding-left: map-get($map: $padding, $key: small);
    padding-right: map-get($map: $padding, $key: small);
  }

  &:hover {
    .Icon {
      fill: darken(map-get($map: $colors-text, $key: light), 10%);
    }
  }

  &.hasFooterSlot {
    border-bottom-right-radius: 0;
  }
}

.CopyText {
  align-items: center;
  display: flex;
}

.CopyIcon {
  fill: map-get($map: $colors-text, $key: light);
  margin-right: map-get($map: $margin, $key: small);
  transition: fill map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);
  width: 15px;
}

.CopiedText {
  align-items: center;
  background: map-get($map: $colors-background, $key: red);
  color: map-get($map: $colors-text, $key: white);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.CopiedIcon {
  fill: map-get($map: $colors-text, $key: white);
  margin-right: map-get($map: $margin, $key: small);
  width: 15px;
}

.FooterSlotWrapper {
  border-bottom: 1px solid map-get($map: $colors-border, $key: input);
  border-bottom-left-radius: map-get($map: $border-radius, $key: large);
  border-bottom-right-radius: map-get($map: $border-radius, $key: large);
  border-left: 1px solid map-get($map: $colors-border, $key: input);
  border-right: 1px solid map-get($map: $colors-border, $key: input);
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: large);
}
