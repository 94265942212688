@import "../node_modules/normalize.css/normalize.css";

/*
Imports that affect styles globally.

These have a :global selector in them.
*/
@import "~css/typography";

/* Import styles we need in this file. */
@import "~css/colors";

:global {
  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background: map-get($map: $colors-background, $key: light-gray);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    height: 100vh;
  }

  a {
    color: map-get($map: $colors-text, $key: link);
  }

  // Toastify custom overrides
  // Can't address stylelint issues properly on the third party library overrides
  /* stylelint-disable selector-class-pattern */
  .Toastify__toast-container {
    max-width: 700px;
    width: 90%;
  }

  .Toastify__toast {
    cursor: default;
  }

  .Toastify__close-button {
    svg {
      height: auto;
      width: 22px;
    }
  }

  .Toastify__toast--success {
    background-color: map-get($map: $colors-background, $key: green);
  }

  .Toastify__toast-container--top-center {
    left: 50%;
    transform: translateX(-50%);
  }
  /* stylelint-enable selector-class-pattern */
}

/* Used by @reach/dialog to detect in JavaScript if that app has loaded styles or not. */

/* Displays a console warning if this isn't here. */
:root {
  --reach-dialog: 1;
}
