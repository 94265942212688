@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";
@import "~css/shared/toast";

h2.Header {
  border-radius: 9px 9px 0 0;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
  padding: map-get($map: $padding, $key: default) * 2;

  @include desktop {
    font-size: 18px;
    padding: map-get($map: $padding, $key: default) * 2
      map-get($map: $padding, $key: xlarge);
  }
}

.Form {
  padding: map-get($map: $padding, $key: default) * 2
    map-get($map: $padding, $key: default) * 2;

  @include desktop {
    padding: map-get($map: $padding, $key: xlarge)
      map-get($map: $padding, $key: xlarge);
  }

  .FormRowNames {
    @include big_mobile {
      display: flex;
      justify-content: space-between;
    }
  }

  .FirstName {
    flex: 1 1 auto;
    margin-bottom: 0;
    margin-top: 0;

    @include big_mobile {
      margin-right: map-get($map: $margin, $key: default);
    }
  }

  .LastName {
    flex: 1 1 auto;
    margin-bottom: 0;

    @include big_mobile {
      margin-top: 0;
    }
  }

  .SubmitReferralButton {
    width: 100%;
  }

  .SmallHorizontalDivider {
    background-color: map-get($map: $colors-border, $key: accent-gray);
    border: 0;
    height: 1px;
    margin: 50px auto 40px;
    width: 110px;
  }

  .OrContainer {
    position: relative;
  }

  .OrText {
    background: #fff;
    color: #aaa;
    font-size: 14px;
    left: 50%;
    padding: 3px 10px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .OrHorizontalDivider {
    margin: 30px auto 20px;
  }

  .SkillTileGridHeader {
    margin-bottom: map-get($map: $margin, $key: xxlarge);

    .HeadingQuestion {
      @include responsive_font(18px, 20px);

      color: map-get($map: $colors-text, $key: black);
      margin: 0 auto;
      max-width: 365px;
    }

    .ErrorText {
      color: map-get($map: $colors-interactive, $key: error);
      font-size: map-get($map: $font-sizes, $key: medium);
      margin-top: map-get($map: $margin, $key: large);
      text-align: center;
    }
  }
}

.SkillTileGrid {
  display: grid;
  grid-gap: 20px 50px;
  grid-template-columns: 1fr;
  margin-bottom: map-get($map: $margin, $key: xxlarge);

  @include mobile {
    grid-template-columns: 1fr 1fr;
  }
}

.isElementDisabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.SkillTile {
  background: map-get($map: $colors-background, $key: white);
  border-color: transparent;
  border-radius: 7px;
  border-style: solid;
  border-width: 3px;
  cursor: pointer;
  padding: map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xlarge);
  position: relative;
  transition: all 0.2s ease-in-out;

  .SkillTileCheckContainer {
    background: map-get($map: $colors-background, $key: dots);
    border-radius: 26px;
    height: 26px;
    opacity: 0;
    padding: 6px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: opacity 0.2s ease-in-out;
    width: 26px;

    .SkillTileCheckIcon {
      fill: map-get($map: $colors-text, $key: white);
    }
  }

  &:hover {
    box-shadow: 0 2px 12px rgba(81, 94, 128, 0.15);

    .SkillTileCheckContainer {
      opacity: 1;
    }
  }

  &.SkillTileSelected {
    border-color: map-get($map: $colors-interactive, $key: success);

    .SkillTileCheckContainer {
      background: map-get($map: $colors-interactive, $key: success);
      opacity: 1;
    }
  }

  &.SkillTileFaded {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  .SkillTileIconContainer {
    margin: 0 auto;
    width: 44px;

    img {
      width: 100%;
    }
  }

  .SkillTileHeading {
    @include responsive_font(14px, 16px);

    color: map-get($map: $colors-text, $key: black);
    font-weight: 800;
    margin-bottom: map-get($map: $margin, $key: small);
  }

  .SkillTileDescription {
    margin-bottom: map-get($map: $margin, $key: xxlarge);
    margin-top: 0;
    text-align: center;
  }

  .SkillTileExampleHeading {
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.05em;
    margin-bottom: map-get($map: $margin, $key: medium);
    text-transform: uppercase;
  }

  .SkillTileExamples {
    font-size: 13px;
    line-height: 140%;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  }
}

.NotLoggedInMessage {
  composes: NotLoggedInMessage from "../ReferCandidateForm/ReferCandidateForm.module.scss";
  margin-bottom: map-get($map: $margin, $key: xxlarge);
}

.NotLoggedInBox {
  composes: NotLoggedInBox from "../ReferCandidateForm/ReferCandidateForm.module.scss";
}

.EmailPhoneContainer {
  border: 1px solid map-get($colors-border, container);
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px 20px 10px;
}

.EmailInput {
  margin-top: 0;
}
