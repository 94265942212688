@import "~css/colors";
@import "~css/layout";
@import "~css/transitions";
@import "~css/utilities";

$checkbox-size: 18px;

.Wrapper {
  display: inline-block;
  vertical-align: middle;
}

.HiddenCheckbox {
  @include hide_visually;
}

.Check {
  fill: map-get($map: $colors-text, $key: white);
  width: 68%;
}

.VisibleCheckbox {
  align-items: center;
  background: map-get($map: $colors-text, $key: white);
  border: 1px solid map-get($map: $colors-border, $key: container);
  border-radius: map-get($map: $border-radius, $key: small);
  cursor: pointer;
  display: flex;
  height: $checkbox-size;
  justify-content: center;
  transition: all map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);
  width: $checkbox-size;

  &.isHovered {
    .Check {
      fill: rgba(map-get($map: $colors-text, $key: light), 0.5);
    }
  }

  &.isChecked {
    background: map-get($map: $colors-text, $key: link);
    border-color: map-get($map: $colors-text, $key: link);

    .Check {
      fill: map-get($map: $colors-text, $key: white);
    }
  }
}
