@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.ReferralsListEmpty {
  color: map-get($map: $colors-text, $key: primary);
}

.NoReferralsImg {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  min-height: 181px; // this min-height is here to make the load-in less jumpy
  width: 314px;

  @include tablet {
    min-height: 246px;
  }
}

.NoReferralsText {
  color: map-get($map: $colors-text, $key: heading);
  text-align: center;
}

.NoReferralsCta {
  margin: map-get($map: $margin, $key: xxlarge) auto
    map-get($map: $margin, $key: xxlarge) * 3;
}
