@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$section-gutter: map-get(
  $map: $padding,
  $key: large,
);

$section-btn-transition-easing: map-get(
    $map: $transition-duration,
    $key: fast,
  )
  map-get(
    $map: $transition-easing,
    $key: easeinout,
  );

.TopWrapper {
  align-items: center;
  background-color: map-get($map: $colors-background, $key: white);
  border-bottom: 1px solid map-get($map: $colors-border, $key: container);
  box-shadow: map-get($map: $colors-shadow, $key: panel);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0 $section-gutter;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.ClearAllLink {
  color: map-get($map: $colors-text, $key: black);
  order: 3;
  outline: 0;
  padding: map-get($map: $padding, $key: large);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;

  @include desktop {
    order: 2;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.ClearAllLinkDisabled {
    display: none;
    pointer-events: none;
    visibility: hidden;
  }
}

.ClearLink {
  color: map-get($map: $colors-text, $key: black);
  outline: 0;
  padding: map-get($map: $padding, $key: large);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;

  @include desktop {
    padding: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.ClearLinkDisabled {
    color: map-get($map: $colors-text, $key: black);
    opacity: 0.275;
    pointer-events: none;
  }
}

.CloseModal {
  background: none;
  border: 0;
  cursor: pointer;
  height: 55px;
  order: 1;
  padding: 0;
  width: 55px;
}

.CloseModalSvg {
  fill: map-get($map: $colors-border, $key: input);
  width: 20px;
}

.SectionsWrapper {
  margin: (map-get($map: $margin, $key: xxlarge) * 2) auto
    (map-get($map: $margin, $key: xxlarge) * 4);
  padding: 0 map-get($map: $padding, $key: large);
  width: 100%;

  @include desktop {
    align-content: space-between;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0;
    margin-bottom: map-get($map: $margin, $key: large);
    margin-top: map-get($map: $margin, $key: large);
    padding: 0;

    > div,
    > span {
      margin-right: map-get($map: $margin, $key: default);
    }
  }
}

.Section {
  background-color: map-get($map: $colors-background, $key: white);
  border-bottom: 1px solid map-get($map: $colors-border, $key: container);
  padding-top: map-get($map: $padding, $key: large);

  @include desktop {
    background-color: transparent;
    border: unset;
    display: inline-block;
    margin-bottom: none;
    padding-top: 0;
  }
}

.SectionInner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.HeadingChevron {
  fill: rgba(map-get($map: $colors-text, $key: default), 0.5);
  transition: fill $section-btn-transition-easing,
    transform map-get($map: $transition-duration, $key: default)
      map-get($map: $transition-easing, $key: easeinout);
  width: 11px;

  &.isOpen {
    transform: rotate(180deg);
  }
}

.SectionHeadingButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: map-get($map: $border-radius, $key: medium);
  display: flex;
  height: 44px;
  justify-content: space-between;
  outline: none;
  padding: map-get($map: $padding, $key: large);
  transition: background-color $section-btn-transition-easing;
  width: 100%;

  @include desktop {
    border: 1px solid rgba(map-get($map: $colors-border, $key: blue-gray), 0.25);
    border-radius: map-get($map: $border-radius, $key: huge);
    padding: $section-gutter;
    width: auto;
  }

  &:focus,
  &:hover {
    .HeadingChevron {
      fill: darken(map-get($map: $colors-border, $key: input), 30%);
    }
  }

  &:hover {
    cursor: pointer;

    @include desktop {
      background: darken(
        map-get($map: $colors-background, $key: light-gray),
        1.5%
      );
      border-color: darken(map-get($map: $colors-border, $key: blue-gray), 20%);
    }
  }

  &:focus,
  &:focus:hover {
    @include desktop {
      background: darken(
        map-get($map: $colors-background, $key: light-gray),
        3.5%
      );
    }
  }
}

.SectionHeading {
  font-weight: normal;
  margin: 0;
}

.FilterSectionWrapper {
  @include desktop {
    padding-top: map-get($map: $padding, $key: medium);
    position: absolute;
    z-index: map-get($map: $z-index, $key: fixed);
  }
}

.FilterSection {
  display: flex;
  flex-flow: column nowrap;
  padding: map-get($map: $padding, $key: medium) $section-gutter 0;

  @include desktop {
    background-color: map-get($map: $colors-background, $key: white);
    border-radius: map-get($map: $border-radius, $key: large);
    box-shadow: map-get($map: $colors-shadow, $key: panel);
  }
}

.FilterOptions {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: map-get($map: $margin, $key: large) + 4px;
  padding: map-get($map: $padding, $key: medium) $section-gutter 0;

  @include desktop {
    column-gap: map-get($map: $margin, $key: default);
    display: inline-grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
  }
}

.FilterSectionActions {
  align-content: space-around;
  align-items: center;
  border-top: 1px solid map-get($map: $colors-border, $key: dark-gray);
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 -#{map-get($map: $margin, $key: large)};
  padding: map-get($map: $padding, $key: default)
    map-get($map: $padding, $key: xlarge) + 4;

  @include desktop {
    display: inline-flex;
  }
}

.CheckboxLabel {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: map-get($map: $margin, $key: medium);
  padding: map-get($map: $padding, $key: small) 0;

  &:hover,
  &.isChecked {
    color: map-get($map: $colors-text, $key: black);
  }
}

.Checkbox {
  margin-right: map-get($map: $margin, $key: medium);
}

.FilterMobileBtn {
  align-items: center;
  background-color: map-get($map: $colors-background, $key: blue);
  border: 0;
  border-radius: 9999px;
  bottom: map-get($map: $margin, $key: xlarge);
  box-shadow: map-get($map: $colors-shadow, $key: panel);
  cursor: pointer;
  display: flex;
  left: 50%;
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: xlarge);
  position: fixed;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transform: translateX(-50%);
  transition: background-color $section-btn-transition-easing;
  z-index: map-get($map: $z-index, $key: fixed);

  &:hover {
    background-color: darken(
      map-get($map: $colors-background, $key: orange),
      8%
    );
  }
}

.FiltersIcon {
  fill: map-get($map: $colors-text, $key: white);
  margin-right: map-get($map: $margin, $key: large);
  width: 28px;
}

.FilterMobileBtnText {
  font-weight: bold;
  white-space: nowrap;
}

.Modal.Modal {
  background-color: lighten(
    map-get($map: $colors-background, $key: light-gray),
    1.5%
  );
}

.JobsFilterMobileBottom {
  background: map-get($map: $colors-background, $key: white);
  border-top: 1px solid map-get($map: $colors-border, $key: accent-gray);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding: map-get($map: $padding, $key: large);
  position: fixed;
  width: 100%;
  z-index: 10;
}

.SeeJobsButton {
  width: 100%;
}

.SearchContainer {
  position: relative;

  input {
    padding-left: 40px;
  }
}

.SearchIcon {
  fill: map-get($map: $colors-text, $key: light);
  height: 21px;
  left: 12px;
  position: absolute;
  top: 12px;
}

.ClearSearchLink {
  position: absolute;
  right: 12px;
  top: 10px;

  &:hover {
    cursor: pointer;
  }

  &.ClearSearchLinkDisabled {
    display: none;
  }
}

.FilterWrapper {
  background: none;
  padding-top: 20px;
}

.searchInput {
  margin: 0;
  outline: 0;
}
