@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.JobsListMessage {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  padding: map-get($map: $padding, $key: xxlarge);
  text-align: center;
}

.JobsListMessageHeading {
  margin-bottom: map-get($map: $margin, $key: small);
}

.JobsListMessageSubheading {
  font-family: $body;
  font-size: map-get($map: $font-sizes, $key: large);
}

.IconContainer {
  margin-bottom: map-get($map: $margin, $key: default);
}

.ClearFiltersButton {
  margin-top: map-get($map: $margin, $key: xlarge);
}
