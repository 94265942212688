@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$max-page-width: 1200px;

.HeroContent {
  margin: (map-get($map: $margin, $key: xxlarge) * 2) auto 0;
  max-width: 1200px;
}

.heroLeft {
  max-width: 600px;
}

h1.HeroHeading {
  color: map-get($map: $colors-text, $key: white);
  font-size: 32px;
  margin: 0 auto;
  text-align: left;

  @include big_mobile {
    font-size: 60px;
    margin-left: 0;
    max-width: 450px;
  }
}

.whoWhatWhyContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: map-get($map: $padding, $key: xxlarge) * 1.5;
  margin: 0 auto;
  max-width: 1200px;
  padding: map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xlarge);

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include tablet {
    padding: map-get($map: $padding, $key: xxlarge) * 2
      map-get($map: $padding, $key: xlarge);
  }
}

.whoWhatWhyTop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  flex-direction: column;

  @include tablet {
    justify-content: flex-start;
  }

  h2 {
    color: map-get($map: $colors-text, $key: purple);
  }

  img {
    width: 80px;
    aspect-ratio: 1;
  }
}

.bigPoints {
  margin: map-get($map: $padding, $key: xxlarge) auto 0;
  max-width: 1200px;
  padding: map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xlarge);

  @include tablet {
    margin: map-get($map: $padding, $key: xxlarge) * 2 auto;
  }
}

.bigPoint {
  display: flex;
  flex-direction: column;
  grid-template-columns: 3fr 4fr;
  grid-gap: 60px;
  margin-bottom: map-get($map: $padding, $key: xxlarge) * 2;

  @include tablet {
    display: grid;
    margin-bottom: map-get($map: $padding, $key: xxlarge) * 4;
  }

  @include desktop {
    margin-bottom: map-get($map: $padding, $key: xxlarge) * 5;
  }

  .bigPointText {
    grid-column: 2;
    grid-row: 1;
  }

  .bigPointImage {
    grid-column: 1;
    grid-row: 1;
  }

  &:nth-of-type(2n) {
    grid-template-columns: 4fr 3fr;

    .bigPointText {
      grid-column: 1;
      grid-row: 1;
    }

    .bigPointImage {
      grid-column: 2;
      grid-row: 1;
    }
  }
}

.bigPointImage {
}

.bigPointText {
  color: map-get($map: $colors-text, $key: purple);
  @include responsive_font(40px, 72px);
  line-height: 1.2;
  margin: auto 0;
  text-align: center;

  h3 {
    margin: 0;
  }

  @include tablet {
    text-align: left;
  }
}
