@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.JobsListHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: map-get($map: $margin, $key: xlarge);
  padding: 0 $page-gutter-mobile;
  position: relative;
}

span.JobsListHeaderHeadingText {
  @include responsive_font(20px, 24px);
}

.ReferAllLink:hover {
  text-decoration: underline;
}

.JobsListHeaderScrollAnchor {
  height: 58px;
  position: absolute;
  top: -58px;
  width: 1px;
  z-index: -1;

  @include desktop {
    height: 120px;
    top: -120px;
  }
}
