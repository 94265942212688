@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$max-page-width: 1200px;

.MarketingHero {
  color: map-get($map: $colors-text, $key: primary);
}

.Hero {
  background-position: bottom center;
  background-size: cover;
  min-height: 400px;
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xlarge)
    (map-get($map: $padding, $key: large) * 2);
  position: relative;

  @include big_mobile {
    padding: map-get($map: $padding, $key: xxlarge)
      map-get($map: $padding, $key: xxlarge)
      (map-get($map: $padding, $key: xxlarge) * 2);
  }

  @include desktop {
    min-height: 610px;
  }
}

.HeroArc {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  width: 105%;
}

.HeroLogo {
  height: auto;
  width: 180px;

  @include big_mobile {
    width: 250px;
  }
}

.NavWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $max-page-width;
  padding: map-get($map: $padding, $key: xlarge) 0;
}

.Nav {
  align-items: center;
  display: flex;
}

.NavLink {
  color: map-get($map: $colors-text, $key: white);
  font-weight: 700;
  padding: map-get($map: $padding, $key: small)
    map-get($map: $padding, $key: xlarge);
  transition: color map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);

  &:hover {
    color: darken(map-get($map: $colors-text, $key: white), 8%);
  }
}

.NavLinkBorderLeft {
  border-left: 1px solid map-get($map: $colors-text, $key: white);
  margin-left: map-get($map: $margin, $key: large);
  padding-left: map-get($map: $padding, $key: xlarge) * 1.25;
}

.NavButton {
  background-color: map-get($map: $colors-background, $key: white);
  border-radius: map-get($map: $border-radius, $key: medium);
  color: darken(map-get($map: $colors-background, $key: orange), 5%);
  font-weight: 700;
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: large);
  transition: color background-color
    map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);

  &:hover {
    background-color: change-color(
      $color: map-get($map: $colors-background, $key: white),
      $alpha: 0.95
    );
    color: darken(map-get($map: $colors-background, $key: orange), 10%);
  }
}

.HamburgerIcon {
  height: 40px;
  padding: 8px;
  width: 40px;

  > span {
    background: map-get($map: $colors-background, $key: white);
    display: block;
    height: 2px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
