.Divider {
  background: linear-gradient(
    to right,
    #fff 0%,
    #e8ebee 40%,
    #e8ebee 60%,
    #fff 100%
  );
  height: 1px;
  width: 100%;
}
