@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.Input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: map-get($map: $margin, $key: xlarge) 0;

  label {
    margin-bottom: map-get($map: $margin, $key: medium);
  }

  input {
    border: 1px solid map-get($map: $colors-border, $key: input);
    border-radius: map-get($map: $border-radius, $key: large);
    box-sizing: border-box;
    font-size: map-get($map: $font-sizes, $key: input);
    padding: map-get($map: $padding, $key: large);
    width: 100%;
  }
}

.Input.Error {
  label {
    color: map-get($map: $colors-interactive, $key: error);
  }

  input {
    border-color: map-get($map: $colors-interactive, $key: error);
  }
}

.ErrorText {
  color: map-get($map: $colors-interactive, $key: error);
  font-size: map-get($map: $font-sizes, $key: small);
  margin-top: map-get($map: $margin, $key: medium);
}
