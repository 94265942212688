@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.JobsListContainer {
  padding: 0 $page-gutter-mobile;
  width: 100%;
}

section.JobsList {
  padding: 0;
  width: 100%;

  @include tablet {
    padding: map-get($map: $padding, $key: large) 0;
  }
}

.Headers {
  height: 0;
  overflow: hidden;

  @include tablet {
    border-bottom: 1px solid map-get($map: $colors-border, $key: accent-gray);
    display: grid;
    grid-column-gap: map-get($map: $margin, $key: xlarge);
    grid-template-columns: 4fr 2fr 40px 1fr;
    height: auto;
    padding: map-get($map: $padding, $key: medium)
      map-get($map: $margin, $key: xxlarge);
  }

  > p {
    display: flex;
    justify-content: center;
    text-transform: uppercase;

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;
    }
  }
}
