@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

// Sizes
.size-small {
  @include responsive_font(
    map-get($map: $font-sizes, $key: small) - 1px,
    map-get($map: $font-sizes, $key: small)
  );

  letter-spacing: 0.2px;
}

.size-medium {
  @include responsive_font(
    map-get($map: $font-sizes, $key: medium) - 1px,
    map-get($map: $font-sizes, $key: medium)
  );

  letter-spacing: 0.225px;
}

.size-large {
  @include responsive_font(
    map-get($map: $font-sizes, $key: large) - 1px,
    map-get($map: $font-sizes, $key: large)
  );

  letter-spacing: 0.25px;
}

.size-xlarge {
  @include responsive_font(
    map-get($map: $font-sizes, $key: xlarge) - 1px,
    map-get($map: $font-sizes, $key: xlarge)
  );

  letter-spacing: 0.25px;
}

// Colors
.color-blue {
  color: map-get($map: $colors-text, $key: link);
}

.color-default {
  color: map-get($map: $colors-text, $key: default);
}

.color-light {
  color: map-get($map: $colors-text, $key: light);
}

.color-green {
  color: map-get($map: $colors-text, $key: green);
}

.color-white {
  color: map-get($map: $colors-text, $key: white);
}

.color-black {
  color: map-get($map: $colors-text, $key: black);
}

// Backgrounds
.bg-white {
  background-color: map-get($map: $colors-background, $key: white);
}

.bg-transparent {
  background: transparent;
}

.bg-green {
  background-color: map-get($map: $colors-background, $key: light-green);
}

.bg-gray {
  background-color: darken(
    map-get($map: $colors-background, $key: accent-gray),
    10%
  );
}

// Font weights
.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

// Caps
.allCaps {
  text-transform: uppercase;
}

// Letter spacing
.letter-spacing-large {
  letter-spacing: 2px;
}
