@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.NavLink {
  border-radius: map-get($map: $border-radius, $key: medium);
  color: map-get($map: $colors-text, $key: light);
  display: inline-block;
  font-size: map-get($map: $font-sizes, $key: medium);
  margin-right: map-get($map: $margin, $key: medium);
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: large);
  transition: all map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);

  &:hover {
    color: map-get($map: $colors-text, $key: default);
  }

  &.isActive {
    background: map-get($map: $colors-background, $key: accent-gray);
    color: map-get($map: $colors-text, $key: default);
    font-weight: 500;
  }
}
