@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$section-btn-transition-easing: map-get(
    $map: $transition-duration,
    $key: fast,
  )
  map-get(
    $map: $transition-easing,
    $key: easeinout,
  );

.ReferralsProcessOverview {
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.ProcessItem {
  overflow: hidden;
}

.OverviewHeading {
  margin-left: map-get($map: $margin, $key: large);
}

.HeaderChevron {
  fill: darken(map-get($map: $colors-border, $key: input), 10%);
  transition: fill $section-btn-transition-easing,
    transform map-get($map: $transition-duration, $key: default)
      map-get($map: $transition-easing, $key: easeinout);
  width: 11px;

  &.isOpen {
    transform: rotate(180deg);
  }
}

.ProcessItemHeader {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-left: 3px solid transparent;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: map-get($map: $padding, $key: large);
  transition: all $section-btn-transition-easing;
  width: 100%;

  &:focus,
  &:hover {
    .HeaderChevron {
      fill: darken(map-get($map: $colors-border, $key: input), 30%);
    }
  }

  &:hover {
    background: darken(
      map-get($map: $colors-background, $key: light-gray),
      1.5%
    );
    cursor: pointer;
  }

  &:focus,
  &:focus:hover {
    background: darken(
      map-get($map: $colors-background, $key: light-gray),
      3.5%
    );
  }

  &.isOpen {
    background: darken(map-get($map: $colors-background, $key: light-gray), 5%);
    border-color: map-get($map: $colors-background, $key: primary);
  }
}

.ProcessItemHeading {
  margin: 0;
}

.ProcessItemContent {
  border-left: 3px solid map-get($map: $colors-border, $key: input);
  padding: map-get($map: $padding, $key: small)
    map-get($map: $padding, $key: medium) map-get($map: $padding, $key: small)
    map-get($map: $padding, $key: large);
}
