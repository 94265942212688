$border-radius: (
  small: 3px,
  medium: 6px,
  large: 9px,
  huge: 70px,
);

$padding: (
  small: 4px,
  medium: 8px,
  default: 10px,
  large: 12px,
  xlarge: 20px,
  xxlarge: 40px,
);

$margin: (
  small: 4px,
  medium: 8px,
  default: 10px,
  large: 12px,
  xlarge: 20px,
  xxlarge: 40px,
);

$z-index: (
  fixed: 50,
  dropdown: 100,
);

$page-max-width: 1500px;
$page-gutter: 100px;
$page-gutter-mobile: 15px;
