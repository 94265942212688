@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.Content {
  display: grid;
  margin: $page-gutter-mobile;
  max-width: $page-max-width;

  @include desktop {
    grid-column-gap: map-get($map: $margin, $key: xlarge);
    grid-template-columns: auto 300px;
    grid-template-rows: max-content auto;
    margin: map-get($map: $margin, $key: xxlarge) auto;
    padding: 0 $page-gutter-mobile;
  }

  @include big_screen {
    grid-column-gap: map-get($map: $margin, $key: xxlarge) * 2;
    padding: 0 $page-gutter;
  }
}

.ReferralList {
  grid-row: 2 / 3;

  @include desktop {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
  }
}

.Money {
  grid-row: 1 / 2;

  @include desktop {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

.ProcessOverview {
  grid-row: 3 / 4;

  @include desktop {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  &.ProcessOverviewLoggedOut {
    grid-row: 2 / 3;

    @include desktop {
      grid-row: 1 / 2;
    }
  }
}
