@import "~css/layout";

.Wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.ContentWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Svg {
  width: 150px;
}

.Text {
  display: block;

  // Setting height here so the logo won't jump if we add/remove text while loading
  height: 80px;
  margin-top: map-get($map: $margin, $key: large);
}
