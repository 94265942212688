/* Primitives */

$black-0: #000;

$blue-5: #011452;
$blue-30: #335aff;
$blue-50: #2f62ff;
$blue-55: #2e76b0; // LinkedIn Blue
$blue-56: #3178ea; // Facebook Blue
$blue-60: #22c7fc;
$blue-80: #a2b5ff;
$blue-90: #e1ebfb;

$blue-gray-50: #6a7c94;
$blue-gray-90: #8999b1;

$orange-50: #f89f24;
$orange-70: #fcc63e;

$red-5: #f22;
$red-70: #e66060;

$github-gray: #25292e;
$gray-30: #55576c;
$gray-35: #d5dae2;
$gray-50: #dce5f5;
$gray-60: #d7dce7;
$gray-70: #eef1f8;
$gray-75: #e6edf4;
$gray-77: #e6eefb;
$gray-80: #f6f9fc;
$gray-90: #f4f7fa;
$gray-95: #f2f6fa;

$green-60: #16a45a;
$green-70: #34d088;
$green-90: #e1ffee;

$purple-10: #292c47;
$purple-20: #26224e; // Github Purple
$purple-30: #424671;
$purple-50: #12236a;
$purple-70: #76788d;
$purple-80: #424572;
$purple-90: #15093a;
$purple-95: #0f062b;

$white: #fff;

$yellow-500: #fdda5f;

// Gradients
$blue-gradient: linear-gradient(180deg, #25b1fd 0%, #345aff 100%);
$orange-gradient: linear-gradient(129deg, #f7941d 0%, #f7941d 0%, #fcc63e 100%);
$dark-orange-gradient: linear-gradient(42deg, #ed8100 0%, #faa13c 100%);
$purple-gradient: linear-gradient(225deg, #4b06af 0%, #140099 100%);
$white-gradient: linear-gradient(129deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

// Shadows
$shadow: 0 5px 8px 0 rgba(18, 18, 20, 0.07);
$giant-shadow: 0 12px 170px 0 rgba(7, 18, 67, 0.5);
$panel-shadow: 0 6px 13px 0 rgba(11, 18, 118, 0.12);
$panel-raised-shadow: 0 6px 20px 4px rgba(11, 18, 118, 0.12);
$focus-shadow: 0 0 5px rgba(51, 90, 255, 0.6);

/* END Primitives */

/* Semantics */
$colors-background: (
  accent-gray: $gray-70,
  dots: $gray-35,
  green: $green-70,
  light-green: $green-90,
  inverted-header: $purple-90,
  light-gray: $gray-90,
  loading: $gray-95,
  orange: $orange-50,
  primary: $purple-30,
  very-light-blue: $blue-90,
  light-blue: $blue-80,
  blue: $blue-30,
  white: $white,
  orange-gradient: $orange-gradient,
  blue-gradient: $blue-gradient,
  dark-orange-gradient: $dark-orange-gradient,
  red: $red-5,
);

$colors-border: (
  accent-gray: $gray-70,
  container: $gray-75,
  light-gray: $gray-90,
  dark-gray: $gray-35,
  blue-gray: $blue-gray-50,
  orange: $orange-50,
  input: $gray-60,
  light-blue-gray: #dce7f8,
);

$colors-icon: ();

$colors-text: (
  accent-gray-text: $blue-gray-50,
  default: $gray-30,
  heading: $black-0,
  light: $blue-gray-50,
  light-gray: $gray-77,
  link: $blue-30,
  gray: $gray-80,
  green: $green-60,
  black: $black-0,
  white: $white,
  blue: $blue-5,
  purple: $purple-50,
);

$colors-interactive: (
  error: $red-70,
  success: $green-70,
);

$colors-time: (
  onTime: $green-60,
  late: $red-70,
  atRisk: $yellow-500,
);

$colors-social: (
  facebook: $blue-56,
  github: $github-gray,
  linkedin: $blue-55,
);

$colors-shadow: (
  default: $shadow,
  giant: $giant-shadow,
  panel: $panel-shadow,
  focus: $focus-shadow,
);

/* END Semantics */
