@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.AuthNav {
  display: none;
  padding: map-get($map: $padding, $key: xlarge);

  @include desktop {
    display: flex;
  }

  .AuthNavRow {
    display: flex;
    flex: 1;

    .AuthNavRowContent {
      display: flex;
      margin-left: auto;

      .AuthNavRowContentText {
        margin-right: map-get($map: $margin, $key: large);
      }
    }
  }
}
