@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$icon-width: 27px;

.ReferralToast {
  border-radius: map-get($map: $border-radius, $key: large);
  font-family: $body;
  top: 1em;

  @include custom(480px) {
    top: 1em;
  }

  @include big_mobile {
    top: 6em;
  }
}

.ReferralSuccessToast,
.ReferralFailureToast {
  padding: map-get($map: $padding, $key: default)
    map-get($map: $padding, $key: xlarge);
}

.ReferralSuccessContent {
  display: grid;
  grid-gap: map-get($map: $margin, $key: large);
  grid-template-columns: $icon-width 1fr;
  margin: map-get($map: $margin, $key: default) auto;
}

.ReferralSuccessIcon {
  height: auto;
  margin: 5px 0 auto;
  width: $icon-width;

  > g > g {
    fill: map-get($map: $colors-text, $key: white);
  }
}

.ReferralSuccessContentText {
  display: grid;
  margin: auto 0;

  @include big_mobile {
    grid-template-columns: 1fr auto;
  }
}

.ReferralSuccessToastHeader {
  font-weight: bold;
  margin-bottom: map-get($map: $margin, $key: small);
  margin-top: 0;

  @include big_mobile {
    margin: 0;
  }
}

.ReferralSuccessToastLink {
  color: map-get($map: $colors-text, $key: white);
  text-decoration: underline;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
}

.ReferralFailureToast {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  padding: 10px 0 10px 16px;

  img {
    width: 35px;
  }

  .ReferralFailureToastHeader {
    font-family: $heading;
    text-align: center;
  }

  .ReferralFailureToastBody {
    font-family: $body;
    font-size: map-get($map: $font-sizes, $key: medium);
    text-align: center;
  }

  .ReferralFailureToastLink {
    color: map-get($map: $colors-text, $key: white);
    text-decoration: underline;
  }
}
