@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$referral-list-item-border-width: 2px;

.ReferralsListItem {
  border: $referral-list-item-border-width solid transparent;
  padding: 0;
  position: relative;
  width: 100%;
}

.ReferralsListItem:not(.isDetailPage) {
  &:hover {
    box-shadow: $panel-raised-shadow;
    cursor: pointer;
  }
}

.WrapperLink {
  display: block;
  padding: (map-get($map: $padding, $key: medium) * 2)
    (map-get($map: $padding, $key: medium) * 2)
    map-get($map: $padding, $key: medium);

  @include big_mobile {
    padding: map-get($map: $padding, $key: xlarge)
      map-get($map: $padding, $key: xlarge) map-get($map: $padding, $key: large);
  }

  @include tablet {
    padding: map-get($map: $padding, $key: xxlarge)
      map-get($map: $padding, $key: xxlarge)
      map-get($map: $padding, $key: xlarge);
  }
}

.TopSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    flex-direction: row;
  }
}

.Name.Name {
  font-size: map-get($map: $font-sizes, $key: large);
  margin-bottom: map-get($map: $margin, $key: small);
  margin-top: 0;

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: xlarge);
    margin-bottom: map-get($map: $margin, $key: medium);
  }
}

.Email.Email,
.Phone.Phone {
  display: block;
  line-height: map-get($map: $line-heights, $key: large);
  margin-bottom: map-get($map: $margin, $key: small);

  @include tablet {
    display: inline;
  }
}

.Email::after {
  @include tablet {
    content: "\2022";
    margin: 0 map-get($map: $margin, $key: small);
  }
}

.RewardInfo {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: map-get($map: $margin, $key: xlarge);

  @include tablet {
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: map-get($map: $margin, $key: medium);
    margin-top: 0;
  }
}

.RewardInfo.LoadingItem {
  height: 60px;
  width: 100px;
}

.RewardInfoLabel.RewardInfoLabel {
  font-size: map-get($map: $font-sizes, $key: small);
  line-height: 1;
  text-align: right;

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium);
    margin-bottom: map-get($map: $margin, $key: small);
  }
}

.RewardAmount.RewardAmount {
  border-radius: map-get($map: $border-radius, $key: medium);
  font-size: map-get($map: $font-sizes, $key: large);
  font-weight: bold;
  margin-right: map-get($map: $margin, $key: medium);
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: large);

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: xlarge) * 0.9;
    margin-right: 0;
    margin-top: map-get($map: $margin, $key: small);
    padding: map-get($map: $padding, $key: large)
      map-get($map: $padding, $key: xlarge);
  }
}

.StatusAndDateWrapper {
  display: flex;
  margin-top: map-get($map: $margin, $key: large);

  @include tablet {
    flex-direction: row;
  }
}

.StatusWrapper {
  display: flex;
  flex-direction: column;
  margin-right: map-get($map: $margin, $key: large);
  width: 50%;

  @include tablet {
    width: 220px;
  }
}

.Status.Status {
  color: map-get($map: $colors-text, $key: heading);
  font-size: map-get($map: $font-sizes, $key: medium);
  font-weight: 600;
  line-height: 1;
  margin-top: map-get($map: $margin, $key: small);

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium) + 1px;
  }
}

.DateWrapper {
  display: flex;
  flex-direction: column;

  @include tablet {
    margin-right: map-get($map: $margin, $key: medium);
  }
}

.Date.Date {
  color: map-get($map: $colors-text, $key: heading);
  font-size: map-get($map: $font-sizes, $key: medium);
  line-height: 1;
  margin-top: map-get($map: $margin, $key: small);

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium);
  }
}

.ReferralsWrapper {
  border: 1px solid map-get($map: $colors-border, $key: container);
  border-radius: map-get($map: $border-radius, $key: large);
  margin-bottom: map-get($map: $margin, $key: large);
  margin-top: map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: large) 0;

  @include tablet {
    padding: map-get($map: $padding, $key: large)
      map-get($map: $padding, $key: large) map-get($map: $padding, $key: small);
  }
}

.JobWrapper {
  display: grid;
  grid-template: auto auto / max-content auto;
  margin-bottom: map-get($map: $margin, $key: xlarge);
  margin-top: map-get($map: $margin, $key: medium);

  @include tablet {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: map-get($map: $margin, $key: large);
  }
}

.BriefcaseSvg {
  visibility: hidden;
  width: 0;

  @include tablet {
    fill: darken(map-get($map: $colors-background, $key: accent-gray), 10%);
    margin-right: map-get($map: $margin, $key: large);
    visibility: visible;
    width: 25px;
  }
}

.ReferralReward.ReferralReward {
  align-self: center;
  border-radius: map-get($map: $border-radius, $key: small);
  font-size: map-get($map: $font-sizes, $key: small);
  font-weight: 600;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  letter-spacing: 0.5px;
  line-height: map-get($map: $line-heights, $key: medium);
  margin-right: map-get($map: $margin, $key: medium);
  padding: map-get($map: $padding, $key: small);

  @include tablet {
    font-size: 16px;
    line-height: map-get($map: $line-heights, $key: large);
    padding: map-get($map: $padding, $key: small)
      map-get($map: $padding, $key: medium);
  }
}

.ReferralHiredWrapper {
  align-items: center;
  display: flex;
  margin-top: map-get($map: $margin, $key: small);

  @include tablet {
    margin-left: map-get($map: $margin, $key: large);
    margin-top: 0;
  }
}

.ReferralHiredCheck {
  fill: map-get($map: $colors-text, $key: green);
  font-weight: 600;
  width: 15px;

  @include tablet {
    width: 20px;
  }
}

.ReferralHiredText.ReferralHiredText {
  font-size: map-get($map: $font-sizes, $key: small);
  font-weight: 600;
  line-height: 1;
  margin-left: map-get($map: $margin, $key: small);

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: large);
  }
}

.ReferralText.ReferralText {
  align-self: center;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  line-height: 1;

  @include tablet {
    margin-top: 0;
  }
}

.ReferralText.ReferralText > * {
  font-size: map-get($map: $font-sizes, $key: small);
  line-height: 1;

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium);
  }
}

.JobName,
.CompanyName {
  color: map-get($map: $colors-text, $key: heading);
  font-weight: 600;
}

.LastUpdated.LastUpdated {
  display: block;
  font-size: map-get($map: $font-sizes, $key: small);
  text-align: right;

  @include tablet {
    font-size: map-get($map: $font-sizes, $key: medium);
  }
}

.JobWrapper.CompletedNotHired {
  .ReferralText > * {
    color: lighten(map-get($map: $colors-text, $key: light), 20%);
    text-decoration: line-through;
  }

  .BriefcaseSvg {
    fill: darken(map-get($map: $colors-background, $key: accent-gray), 3%);
  }
}

// Loading
.LoadingItem.LoadingItem {
  align-items: center;
  color: rgba(255, 255, 255, 0);
  display: flex;
  overflow: hidden;
  position: relative;

  &::before {
    background: map-get($map: $colors-background, $key: loading);
    border: 0;
    border-radius: map-get($map: $border-radius, $key: small);
    content: "";
    display: block;
    height: 80%;
    position: absolute;
    width: 100%;
  }
}

.ReferralLoadingItem {
  height: 25px;
  margin-bottom: map-get($map: $margin, $key: large);
}

// ReferralsDetail
.ReferralsDetailWrapper {
  margin-top: map-get($map: $margin, $key: xxlarge);
}

.ReferralsDetailItem {
  border: 1px solid map-get($map: $colors-border, $key: container);
  border-radius: map-get($map: $border-radius, $key: large);
  margin-bottom: map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: medium);

  @include tablet {
    padding: map-get($map: $padding, $key: large);
  }
}

.ReferralsDetailItemTopSection {
  display: flex;
  flex-direction: column;
  padding: 0 map-get($map: $padding, $key: large);

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }
}

.ReferralDetailItemHeadingWrapper {
  align-items: center;
  display: flex;
}

.ReferralsDetailHeading {
  margin: 0;
}

.ReferralsDetailItemReferredOn {
  display: flex;
  flex-direction: row;

  @include tablet {
    flex-direction: column;
    margin-top: map-get($map: $margin, $key: large);
  }
}

.ReferralsDetailItemReferredOn > :first-child::after {
  content: ": ";
  margin-right: map-get($map: $margin, $key: small);

  @include tablet {
    display: none;
  }
}

.ReferralWorkflowWrapper {
  display: flex;
  flex-direction: column;
  margin-top: map-get($map: $margin, $key: xlarge);
  padding: 0 map-get($map: $padding, $key: medium);

  @include tablet {
    flex-direction: row;
    padding: 0;
  }
}

.ReferralWorkflowStep {
  column-gap: map-get($map: $margin, $key: medium);
  display: grid;
  grid-template: auto auto / max-content auto;
  padding-bottom: map-get($map: $margin, $key: large);
  position: relative;

  @include tablet {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding-bottom: 0;
    text-align: center;
  }
}

$workflow-node-size: 16px;

.ReferralWorkflowStep::after {
  background: map-get($map: $colors-border, $key: input);
  content: "";
  display: block;
  height: 100%;
  left: ($workflow-node-size / 2) - ($workflow-node-size / 16);
  position: absolute;
  width: $workflow-node-size / 8;
  z-index: 0;

  @include tablet {
    height: $workflow-node-size / 8;
    left: 0;
    top: ($workflow-node-size / 2) - ($workflow-node-size / 16);
    width: 100%;
  }
}

.ReferralWorkflowStep:first-of-type::after {
  @include tablet {
    left: unset;
    right: 0;
    width: 50%;
  }
}

.ReferralWorkflowStep:last-of-type::after {
  display: none;

  @include tablet {
    display: block;
    width: 50%;
  }
}

.ReferralWorkflowStepNode {
  align-self: center;
  background: map-get($map: $colors-background, $key: white);
  border: ($workflow-node-size / 8) solid
    map-get($map: $colors-border, $key: input);
  border-radius: 9999px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  height: $workflow-node-size;
  position: relative;
  width: $workflow-node-size;
  z-index: 1;

  @include tablet {
    margin: 0 auto map-get($map: $margin, $key: medium);
  }
}

.ReferralWorkflowStep.isCompletedStep {
  .ReferralWorkflowStepNode::after {
    background: map-get($map: $colors-border, $key: input);
    border-radius: 9999px;
    content: "";
    display: block;
    height: $workflow-node-size / 2;
    left: $workflow-node-size / 8;
    position: absolute;
    top: $workflow-node-size / 8;
    width: $workflow-node-size / 2;
  }
}

.ReferralWorkflowStep.isLatestStep {
  .ReferralWorkflowStepNode {
    border-color: map-get($map: $colors-text, $key: link);
  }

  .ReferralWorkflowStepNode::after {
    background: map-get($map: $colors-text, $key: link);
  }
}

.ReferralWorkflowStep.isStruckThrough span {
  text-decoration: line-through;
}

.ReferralWorkflowStepName.ReferralWorkflowStepName {
  align-self: center;
  grid-column: 2 /3;
  grid-row: 1 / 2;
  line-height: 1;

  @include tablet {
    margin-bottom: map-get($map: $margin, $key: small);
    padding: 0 map-get($map: $padding, $key: medium);
  }
}

.ReferralWorkflowStepDate.ReferralWorkflowStepDate {
  grid-column: 2 /3;
  grid-row: 2 / 3;
  line-height: 1;

  @include tablet {
    margin-bottom: map-get($map: $margin, $key: small);
  }
}
