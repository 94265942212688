@import "~css/animations";
@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$btn-transition-easing: map-get(
    $map: $transition-duration,
    $key: fast,
  )
  map-get(
    $map: $transition-easing,
    $key: easeinout,
  );

.ArrowLeft {
  fill: map-get($map: $colors-text, $key: light);
  margin-right: map-get($map: $margin, $key: large);
  transition: transform map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);
  width: 15px;

  &:hover {
    fill: darken(map-get($map: $colors-text, $key: light), 10%);
  }
}

.Button {
  align-items: center;
  border: 1px solid transparent;
  border-radius: map-get($map: $border-radius, $key: medium);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: map-get($map: $font-sizes, $key: medium);
  font-weight: 600;
  justify-content: center;
  outline: 0;
  transition: background-color $btn-transition-easing,
    border-color $btn-transition-easing;

  &:hover {
    .ArrowLeft {
      fill: darken(map-get($map: $colors-text, $key: light), 10%);
      transform: translateX(-5px);
    }
  }
}

.variant-primary {
  background-color: map-get($map: $colors-background, $key: blue);
  color: map-get($map: $colors-text, $key: white);

  // not sure how to fix this without significant change
  /* stylelint-disable-next-line no-descending-specificity */
  .ArrowLeft {
    fill: map-get($map: $colors-text, $key: white);
  }

  &:hover {
    background-color: darken(map-get($map: $colors-background, $key: blue), 5%);

    .ArrowLeft {
      fill: darken(map-get($map: $colors-text, $key: white), 10%);
    }
  }
}

.variant-secondary {
  background-color: transparent;
  border-color: map-get($map: $colors-border, $key: input);
  color: map-get($map: $colors-text, $key: light);

  // not sure how to fix this without significant change
  /* stylelint-disable-next-line no-descending-specificity */
  .ArrowLeft {
    fill: map-get($map: $colors-text, $key: light);
  }

  &:hover {
    border-color: darken(map-get($map: $colors-border, $key: input), 10%);
    color: darken(map-get($map: $colors-text, $key: light), 10%);

    .ArrowLeft {
      fill: darken(map-get($map: $colors-text, $key: light), 10%);
    }
  }
}

.variant-green {
  background-color: map-get($map: $colors-background, $key: green);
  border-color: map-get($map: $colors-background, $key: green);
  color: map-get($map: $colors-text, $key: white);

  // not sure how to fix this without significant change
  /* stylelint-disable-next-line no-descending-specificity */
  .ArrowLeft {
    fill: map-get($map: $colors-text, $key: white);
  }

  &:hover,
  &:focus {
    background-color: darken(
      map-get($map: $colors-background, $key: green),
      5%
    );
    border-color: darken(map-get($map: $colors-background, $key: green), 5%);

    .ArrowLeft {
      fill: map-get($map: $colors-text, $key: white);
    }
  }
}

.variant-text-link {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: map-get($map: $colors-text, $key: light);
  display: flex;
  justify-content: flex-start;
  outline: none;
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: small) !important;
  width: 100%;

  &:hover {
    color: darken(map-get($map: $colors-text, $key: light), 10%);
  }
}

.size-default {
  font-size: map-get($map: $font-sizes, $key: medium);
  padding: map-get($map: $padding, $key: large)
    map-get($map: $padding, $key: xxlarge);
}

.size-small {
  font-size: map-get($map: $font-sizes, $key: small);
  padding: map-get($map: $padding, $key: medium)
    map-get($map: $padding, $key: large);
}

// Loading state
.Button.is-loading {
  background-color: map-get($map: $colors-background, $key: white);
  border: 1px solid map-get($map: $colors-border, $key: orange);
  padding: map-get($map: $padding, $key: default)
    map-get($map: $padding, $key: xxlarge);
  pointer-events: none;

  .LoadingIcon {
    animation: $rotate360;
    height: 22px;
    width: 22px;
  }
}

.Button.selected {
  box-shadow: 0 6px 13px 0 rgba(11, 18, 118, 0.15);
  color: #f7941d;
}

.Button:focus-visible {
  box-shadow: map-get($map: $colors-shadow, $key: focus);
}

.disabled {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}
