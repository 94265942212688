@import "~css/colors";
@import "~css/typography";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";

.Settings {
  margin: $page-gutter-mobile auto;
  max-width: 600px;
  padding: 0 $page-gutter-mobile;
}

.accountSettingsHeader {
  border-bottom: 1px solid map-get($map: $colors-border, $key: accent-gray);
  margin-bottom: map-get($map: $margin, $key: xlarge);
  padding: map-get($map: $margin, $key: xlarge) 0;
  width: 100%;

  h1 {
    font-size: 24px;
    margin: 0;
  }
}

.errorMessageContainer {
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.nameInputRow {
  display: flex;
  justify-content: space-between;
}

.halfWidthInput {
  width: 48%;
}

.phoneHookInput {
  margin-bottom: map-get($map: $margin, $key: default);
}

.mobilePhoneInputContainer {
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.emailRadioGroup {
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.inputNote {
  font-size: map-get($map: $font-sizes, $key: small);
}

.inputGroup {
  margin-bottom: map-get($map: $margin, $key: xxlarge);
}

.oauthContainer {
  border: 1px solid map-get($map: $colors-border, $key: dark-gray);
  border-radius: map-get($map: $border-radius, $key: small);
  display: flex;
  padding: map-get($map: $padding, $key: large);
}

.oauthTitle {
  color: map-get($map: $colors-text, $key: medium);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: map-get($map: $margin, $key: default);
  margin-top: 0;
}

.oauthDescription {
  color: map-get($map: $colors-text, $key: default);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}

.socialIcon {
  height: 20px;
  margin-right: map-get($map: $margin, $key: default);
  width: 20px;
}

.updatePasswordContainer {
  border-left: 4px solid map-get($map: $colors-border, $key: dark-gray);
  padding: map-get($map: $padding, $key: large) 0px
    map-get($map: $padding, $key: large) map-get($map: $padding, $key: xlarge);

  > div:last-of-type {
    margin-bottom: 0;
  }
}
