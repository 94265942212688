@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.quotes {
  padding: 180px 0;
  width: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
}

.window {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out;
  margin: 0 auto;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  justify-content: center;

  @include tablet {
    padding-right: map-get($map: $padding, $key: xxlarge) * 2;
    justify-content: flex-end;
  }
}

.button {
  background-color: map-get($map: $colors-background, $key: orange);
  border: none;
  border-radius: 50%;
  width: 50px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 map-get($map: $margin, $key: xlarge);
  cursor: pointer;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

.quoteContainer {
  padding: 0 20px;
  overflow: visible;
}

.quote {
  background-color: map-get($map: $colors-background, $key: white);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 38px;
  height: 100%;
  opacity: 0.4;
  transform: translateY(-30px);

  transition: all 0.3s ease-in-out;
}

.activeQuote {
  opacity: 1;
  transform: translateY(0);
}

.textContainer {
  flex: 1;
  margin-bottom: map-get($map: $padding, $key: xlarge);
}

.text {
  color: map-get($map: $colors-text, $key: black);
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.speaker {
  color: map-get($map: $colors-text, $key: black);
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 6px;
}

.title {
  color: map-get($map: $colors-text, $key: black);
  font-size: 16px;
  font-weight: 700;
  display: block;
}
