@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.page {
  background-color: map-get($colors-background, white);
}

.Content {
  display: flex;
  flex-direction: column;

  @include desktop {
    grid-column-gap: map-get($map: $margin, $key: xlarge);
    grid-template-columns: auto 300px;
    grid-template-rows: max-content auto;
    margin: map-get($map: $margin, $key: xxlarge) auto;
    margin-top: 0;
  }

  @include big_screen {
    grid-column-gap: map-get($map: $margin, $key: xxlarge) * 2;
  }
}

.metrics {
  display: flex;
  flex-direction: row;
  // padding: $page-gutter-mobile;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.metric {
  text-align: center;
  margin: 0 map-get($map: $margin, $key: xxlarge);
}

.metricTitle {
  font-size: 1rem;
  color: map-get($map: $colors-text, $key: default);
  margin: 0 0 map-get($map: $padding, $key: small);
}

.metricCount {
  color: map-get($map: $colors-text, $key: blue);
  font-size: 2.6rem;
  font-weight: bold;
  margin: 0 0 map-get($map: $padding, $key: small);
}

.candidates {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  height: 100%;
  max-height: calc(100vh - 179px);
  padding: 0 $page-gutter-mobile $page-gutter-mobile;
}

.column {
  max-height: 90vh;
  max-width: 400px;
  margin-right: 20px;
}

.bucket {
  // column
  background: map-get($map: $colors-background, $key: light-gray);
  flex: 1;
  border-radius: 8px;
  border: 1px solid map-get($map: $colors-border, $key: light-blue-gray);
  margin: 0 map-get($map: $padding, $key: default);
  min-width: 400px;
  height: 100%;
  padding: map-get($map: $padding, $key: large);
  overflow: auto;
}

.bucketCandidates {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.bucketTop {
  display: flex;
  align-items: center;
}

.bucketCount {
  background: #dfe4ec;
  font-size: 1rem;
  font-weight: 500;
  margin-right: map-get($map: $margin, $key: default);
  padding: 3px 8px 2px;
  border-radius: 14px;
}

.bucketTitle {
  font-size: 1.125rem;
  font-weight: bold;
  color: map-get($map: $colors-text, $key: blue);
}

.bucketSpread {
  color: map-get($map: $colors-text, $key: blue);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-weight: bold;
}

.bucketSpreadTitle {
  font-size: 12px;
  font-weight: normal;
}

.candidate {
  background: map-get($map: $colors-background, $key: white);
  border: 1px solid map-get($map: $colors-border, $key: light-blue-gray);
  border-left: 5px solid;
  border-radius: 8px;
  color: map-get($map: $colors-text, $key: default);
  padding: map-get($map: $padding, $key: medium) * 2;
  margin-left: 0;
  margin-bottom: 10px;
}

.candidateTime-green {
  border-left-color: map-get($map: $colors-time, $key: onTime);
}

.candidateTime-red {
  border-left-color: map-get($map: $colors-time, $key: late);
}

.candidateTime-yellow {
  border-left-color: map-get($map: $colors-time, $key: atRisk);
}

.candidateHeader {
  display: grid;
  grid-template-columns: auto auto 1fr;
  margin-bottom: 15px;
}

.candidateName {
  font-size: 18px;
  font-weight: 500;
  color: map-get($map: $colors-text, $key: blue);
  grid-column: 1 / span 3;
  grid-row: 1;
}

.candidateContact {
  display: inline-block;
  font-size: 13px;
  margin-top: 3px;

  &:nth-of-type(3) {
    margin-left: 5px;

    // Place a 4px gray dot in the before element
    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      background: map-get($map: $colors-text, $key: light);
      border-radius: 50%;
      margin-right: 5px;
      transform: translateY(-2.5px);
    }
  }
}

.candidateSpread {
  grid-column: 3;
  margin-left: auto;
  grid-row: 1 / span 2;
}

.candidateSpreadBox {
  background-color: map-get($map: $colors-background, $key: light-gray);
  border-radius: 5px;
  display: block;
  font-size: 14px;
  padding: map-get($map: $padding, $key: medium);
}

.candidateJobs {
  margin-right: 10px;
  padding: 10px 12px 14px;
  border: 1px solid map-get($map: $colors-border, $key: light-blue-gray);
  border-radius: 5px;
}

.candidateFooter {
  margin-top: 10px;
}

.candidateFooterDate {
  font-size: 12px;
  opacity: 0.8;

  &:first-of-type {
    &::after {
      content: "";
      display: inline-block;
      width: 4px;
      opacity: 0.6;
      height: 4px;
      background: map-get($map: $colors-text, $key: light);
      border-radius: 50%;
      margin-left: 9px;
      margin-right: 7px;
      transform: translateY(-2px);
    }
  }
}

.JobWrapper {
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 5px;
}

.referredToLabel {
  font-size: 12px;
  margin-bottom: 5px;
}

.BriefcaseSvg {
  height: 20px;
  width: 20px;
  fill: #d0d8e3;
  margin-right: 5px;
}

.ReferralText {
  color: map-get($map: $colors-text, $key: blue);
  line-height: 130%;
}

.JobName,
.CompanyName,
.joinerAt {
  line-height: 100%;
}

.JobName,
.CompanyName {
  font-weight: 600;
}

.contactTimeframes {
  color: map-get($map: $colors-text, $key: accent-gray-text);

  .contactTimeframesTitle {
    font-size: 11px;
    margin-bottom: 4px;
    text-align: center;
    width: 100%;
  }
}

.times {
  display: flex;
  justify-content: center;

  > span {
    display: block;
    font-size: 12.5px;
    padding-left: 5px;
    padding-right: 10px;

    &::before {
      content: "";
      display: inline-block;
      width: 9px;
      height: 9px;
      background: map-get($map: $colors-text, $key: light);
      border-radius: 50%;
      margin-right: 5px;
    }

    &.green::before {
      background: map-get($map: $colors-time, $key: onTime);
    }

    &.red::before {
      background: map-get($map: $colors-time, $key: late);
    }

    &.yellow::before {
      background: map-get($map: $colors-time, $key: atRisk);
    }
  }
}
