@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";

$modal-side-padding: map-get(
  $map: $padding,
  $key: xxlarge,
);
$modal-side-padding-mobile: map-get(
  $map: $padding,
  $key: xlarge,
);

.Overlay {
  background: hsla(0, 0%, 0%, 0.33);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
}

.Modal {
  background: map-get($map: $colors-background, $key: white);
  border-radius: map-get($map: $border-radius, $key: small);
  display: flex;
  flex-direction: column;
  margin: 0;
  outline: none;
  overflow-y: hidden;
}

.Header {
  border-bottom: 1px solid map-get($map: $colors-border, $key: container);
  display: grid;
  grid-template-columns: auto 40px;
  height: 40px;
  margin-top: map-get($map: $margin, $key: small);
  padding: 0 map-get($map: $padding, $key: small)
    map-get($map: $margin, $key: small) $modal-side-padding-mobile;
  width: 100%;

  @include tablet {
    margin-top: map-get($map: $margin, $key: large);
    padding: 0 map-get($map: $padding, $key: large)
      map-get($map: $margin, $key: large) $modal-side-padding;
  }
}

.Title {
  align-items: center;
  display: flex;
  grid-column: 1 / 2;
  margin: 0 map-get($map: $margin, $key: xlarge) 0 0;
}

.CloseSvg {
  fill: map-get($map: $colors-border, $key: input);
  transition: fill map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);
  width: 18px;
}

.Close {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  grid-column: 2 / 3;
  justify-content: center;
  padding: 0;

  &:hover .CloseSvg {
    fill: darken(map-get($map: $colors-border, $key: input), 15%);
  }
}

.Content {
  overflow-y: auto;

  &.isPadded {
    padding-bottom: map-get($map: $padding, $key: xlarge);
    padding-left: $modal-side-padding-mobile;
    padding-right: $modal-side-padding-mobile;
    padding-top: map-get($map: $padding, $key: medium);

    @include tablet {
      padding: map-get($map: $padding, $key: medium) $modal-side-padding
        map-get($map: $padding, $key: xlarge);
    }
  }
}

.size-default {
  margin: 2vh auto;
  max-height: 96vh;
  max-width: 500px;
  width: 94vw;

  @include tablet {
    margin: 4vh auto;
    max-height: 92vh;
    width: 88vw;
  }
}

.size-full {
  border-radius: 0;
  height: 100vh;
  width: 100vw;
}
