@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.RuleWithText {
  margin: map-get($map: $margin, $key: large) 0;
  position: relative;
  text-align: center;

  > p {
    display: inline-block;
    padding: 0 map-get($map: $padding, $key: medium);
    position: relative;
    z-index: 2;
  }

  &::after {
    background-image: linear-gradient(
      to right,
      #fff 0%,
      #e8ebee 15%,
      #e8ebee 40%,
      #e8ebee 85%,
      #fff 100%
    );
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}
