@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.Icon {
  fill: map-get($map: $colors-text, $key: default);
  height: 12px;
  margin-right: map-get($map: $margin, $key: medium);
  transform: translateY(1px);
  width: auto;

  @include big_mobile {
    margin-right: map-get($map: $margin, $key: small);
  }
}

.Industry {
  align-items: baseline;
  color: map-get($map: $colors-text, $key: primary);
  display: flex;

  &[data-icon="Technology Services"] {
    .Icon {
      transform: translateY(1.5px);
    }
  }

  &[data-icon="Retail"] {
    .Icon {
      transform: translateY(0);
    }
  }

  &[data-icon="Other"] {
    .Icon {
      transform: translateY(2px);
    }
  }
}
