@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$max-page-width: 1200px;

.HeroContent {
  margin: (map-get($map: $margin, $key: xxlarge) * 2) auto 0;
  max-width: 1200px;
}

.heroLeft {
  max-width: 600px;
}

.HeroPadding {
  padding: map-get($map: $padding, $key: xxlarge) * 2 0;
}

h1.HeroHeading {
  color: map-get($map: $colors-text, $key: white);
  font-size: 32px;
  margin: 0 auto;
  text-align: left;

  @include big_mobile {
    font-size: 60px;
    margin-left: 0;
    max-width: 450px;
  }
}

h1.HeroHeadingSmall {
  color: map-get($map: $colors-text, $key: white);
  font-size: 32px;
  margin: 0 auto;

  @include big_mobile {
    font-size: 48px;
  }
}

.SearchForm {
  align-items: center;
  display: flex;
  margin: map-get($map: $margin, $key: xxlarge) auto 0 0;

  @include big_mobile {
    width: 650px;
  }
}

.SearchInput {
  border: 0;
  border-bottom-left-radius: map-get($map: $border-radius, $key: medium);
  border-top-left-radius: map-get($map: $border-radius, $key: medium);
  display: block;
  flex-grow: 1;
  height: 64px;
  outline: none;
  padding: 0 map-get($map: $padding, $key: xlarge);

  &:focus {
    box-shadow: map-get($map: $colors-shadow, $key: focus);
  }
}

.SearchButton {
  align-items: center;
  background: linear-gradient(260.77deg, #6d18d4 0%, #140396 96.53%);
  border: 0;
  border-bottom-right-radius: map-get($map: $border-radius, $key: medium);
  border-top-right-radius: map-get($map: $border-radius, $key: medium);
  display: flex;
  height: 64px;
  justify-content: center;
  transition: background map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);
  width: 74px;

  > svg {
    transition: transform map-get($map: $transition-duration, $key: fast)
      map-get($map: $transition-easing, $key: easeinout);
  }

  &:hover,
  &:focus {
    background: linear-gradient(
      260.77deg,
      darken(#6d18d4, 3%) 0%,
      darken(#140396, 3%) 96.53%
    );
    cursor: pointer;

    > svg {
      transform: scale(1.1);
    }
  }
}

.HeaderBottomText {
  margin-bottom: map-get($map: $margin, $key: xxlarge);
  margin-top: map-get($map: $margin, $key: xlarge);
}

.HeaderTextLink {
  color: map-get($map: $colors-text, $key: white);
  font-weight: 700;
  transition: opacity map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);

  &:hover {
    opacity: 0.9;
  }
}

.MainContent {
  margin: 0 auto;
  max-width: 1200px;
  padding: map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xlarge);
}

h3.LargeHeading {
  font-size: map-get($map: $font-sizes, $key: large);
  margin-bottom: map-get($map: $margin, $key: large);
  margin-top: 0;

  @include big_mobile {
    font-size: 42px;
  }
}

.LargeParagraph {
  max-width: 600px;
}

.SplitTextSection {
  display: flex;
  flex-direction: column;
  margin-bottom: map-get($map: $margin, $key: xxlarge);

  @include big_mobile {
    column-gap: map-get($map: $margin, $key: xxlarge);
    display: grid;
    grid-template-columns: 1fr 1fr;

    h3 {
      font-size: map-get($map: $font-sizes, $key: xlarge);
    }
  }

  h3 {
    font-size: map-get($map: $font-sizes, $key: large);
    margin-bottom: map-get($map: $margin, $key: medium);
  }
}

.JobsList {
  position: relative;

  &::after {
    background: linear-gradient(
      180deg,
      change-color(
          $color: map-get($map: $colors-background, $key: light-gray),
          $alpha: 0
        )
        10%,
      map-get($map: $colors-background, $key: light-gray) 70%
    );
    bottom: 0;
    content: "";
    height: 200px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.BigReward {
  background: map-get($map: $colors-background, $key: light-green);
  border-radius: map-get($map: $border-radius, $key: large);
  box-shadow: 0 4px 44px rgba(8, 18, 101, 0.06);
  padding: map-get($map: $padding, $key: xxlarge)
    map-get($map: $padding, $key: xlarge);
  position: relative;
  text-align: center;
  z-index: 10;

  @include big_mobile {
    padding: map-get($map: $padding, $key: xxlarge) * 2
      map-get($map: $padding, $key: xxlarge);
  }

  > span {
    display: block;
  }

  .BigRewardNumber {
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: map-get($map: $margin, $key: xlarge);

    @include big_mobile {
      font-size: 120px;
    }
  }
}

.TextContent {
  @include responsive_font(15px, 17px);

  margin: map-get($map: $margin, $key: xxlarge) * 2 auto;
  max-width: 650px;

  h3 {
    font-size: map-get($map: $font-sizes, $key: large);
  }
}

.stats {
  background-color: map-get($map: $colors-background, $key: white);
}

.statsContent {
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  width: 100%;

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.stat {
  padding: map-get($map: $padding, $key: xxlarge) 0;
  text-align: center;

  @include tablet {
    padding: map-get($map: $padding, $key: xxlarge) * 2 0;
  }
}

.statNumber {
  color: map-get($map: $colors-text, $key: purple);
  font-size: 38px;
  display: block;
  font-weight: 700;
  line-height: 1;
  margin-bottom: map-get($map: $margin, $key: medium);
}

.statText {
  color: map-get($map: $colors-text, $key: purple);
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}

.statsArc {
  display: block;
  height: auto;
  left: 0;
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
}

.realHumans {
  background: linear-gradient(261.48deg, #6517ce 8%, #180599 82%);
  padding: 140px 0;
}

.realHumansContent {
  max-width: 1200px;
  padding: 0 map-get($map: $padding, $key: xxlarge);
  margin: 0 auto;
  display: grid;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
}

.realHumansImage {
  border: 10px solid white;
  border-radius: 50%;
  box-shadow: $panel-shadow;
  width: 60%;
  margin: 0 auto map-get($map: $margin, $key: xxlarge);

  @include tablet {
    width: 90%;
    margin-left: 0;
  }
}

.realHumansText {
  width: 100%;

  @include tablet {
    margin-left: auto;
    max-width: 560px;
  }
}

.realHumansHeading {
  color: map-get($map: $colors-text, $key: white);
  font-size: map-get($map: $font-sizes, $key: xxlarge) * 2;
  margin-bottom: map-get($map: $margin, $key: xxlarge);
  margin-top: 0;
}
