@import "node_modules/mathsass/dist/math";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://use.typekit.net/bcq6dgk.css");
@import "./colors";

$heading: proxima-nova, sans-serif;
$body: proxima-nova, sans-serif;

$font-sizes: (
  small: 13px,
  medium: 16px,
  input: 16px,
  large: 22px,
  xlarge: 28px,
  xxlarge: 32px,
);

$line-heights: (
  small: 13px,
  medium: 16px,
  large: 22px,
  xlarge: 28px,
  xxlarge: 32px,
);

:global {
  body {
    color: map-get($colors-text, default);
    font-family: $body;
    font-size: map-get($map: $font-sizes, $key: medium);
  }

  a {
    color: map-get($colors-interactive, primary);
    text-decoration: none;
  }
}

@mixin responsive_font($min, $max) {
  $min-wo-px: $min / 1px;
  $max-wo-px: $max / 1px;
  $avg: ($min + $max) / 2;
  $avg-wo-px: $avg / 1px;

  font-size: $min;
  line-height: #{$min-wo-px + sqrt($min-wo-px) * 2.5}px;

  @include tablet {
    font-size: $avg;
    line-height: #{$avg-wo-px + sqrt($avg-wo-px) * 2.5}px;
  }

  @include desktop {
    font-size: #{$max};
    line-height: #{$max-wo-px + sqrt($max-wo-px) * 2.5}px;
  }
}

@mixin responsive_font_important($min, $max) {
  $min-wo-px: $min / 1px;
  $max-wo-px: $max / 1px;
  $avg: ($min + $max) / 2;
  $avg-wo-px: $avg / 1px;

  font-size: $min !important;
  line-height: #{$min-wo-px + sqrt($min-wo-px) * 2.5}px !important;

  @include tablet {
    font-size: $avg !important;
    line-height: #{$avg-wo-px + sqrt($avg-wo-px) * 2.5}px !important;
  }

  @include desktop {
    font-size: #{$max} !important;
    line-height: #{$max-wo-px + sqrt($max-wo-px) * 2.5}px !important;
  }
}
