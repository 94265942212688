@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

$max-page-width: 1200px;

.main {
  overflow-x: hidden;
}

.PillButton {
  background-color: map-get($map: $colors-background, $key: white);
  border-radius: 9999px;
  color: map-get($map: $colors-background, $key: orange);
  display: inline-block;
  font-weight: 700;
  padding: map-get($map: $padding, $key: xlarge)
    map-get($map: $padding, $key: xlarge);
  transition: background-color map-get($map: $transition-duration, $key: fast)
    map-get($map: $transition-easing, $key: easeinout);

  &:hover {
    background-color: change-color(
      $color: map-get($map: $colors-background, $key: white),
      $alpha: 0.9
    );
  }
}

.Footer {
  background: linear-gradient(42deg, #ed8100 0%, #faa13c 100%);
  margin-top: map-get($map: $margin, $key: xxlarge);
  padding: map-get($map: $padding, $key: xxlarge) * 2
    map-get($map: $padding, $key: xxlarge);
  position: relative;

  .FooterHeading {
    color: map-get($map: $colors-text, $key: white);
    font-size: 42px;

    @include big_mobile {
      font-size: 68px;
      max-width: 430px;
    }
  }

  .PillButton {
    margin-bottom: map-get($map: $margin, $key: xxlarge);
    margin-top: map-get($map: $margin, $key: xlarge);
  }
}

.FooterContent {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $max-page-width;
  text-align: center;

  @include desktop {
    flex-direction: row;
    text-align: left;

    > div {
      width: 50%;

      &:first-of-type {
        padding-right: map-get($map: $padding, $key: xlarge);
      }
    }
  }
}

.FooterArc {
  display: block;
  height: auto;
  left: -2px;
  position: absolute;
  top: -2px;
  transform: rotate(180deg);
  width: 105%;
}

.ResumesImg {
  width: 100%;
}
