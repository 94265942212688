@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.Label {
  color: map-get($map: $colors-text, $key: primary);
  font-size: map-get($map: $font-sizes, $key: small);
  font-weight: 500;
  letter-spacing: 0.5px;
}
