@import "~css/colors";
@import "~css/media";
@import "~css/typography";

.HowItWorksTimelineItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;

  @include tablet {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.words {
  color: map-get($colors-text, purple);
  font-size: 24px;
  line-height: 32px;
  margin: 0 auto 50px;
  max-width: 540px;
  order: 2;
  text-align: center;
  width: 100%;

  @include tablet {
    margin-bottom: 0;
    order: 1;
    text-align: left;
    width: 35%;
  }

  p {
    color: map-get($colors-text, purpleLight);
    margin-top: 16px;
  }
}

.itemHeading {
  h1,
  h2,
  h3,
  h4 {
    text-align: center;

    @include tablet {
      text-align: left;
    }
  }
}

.timelinePoint {
  display: flex;
  flex-direction: column;
  order: 1;
  position: relative;
  width: 20%;
  display: none;

  @include tablet {
    display: flex;
    order: 2;
  }

  svg {
    display: none;

    @include tablet {
      display: flex;
      margin: 0 auto;
    }
  }
}

.pointName {
  align-items: center;
  color: map-get($colors-border, orange);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
  padding: 15px 0;

  @include tablet {
    transform: translateX(calc(50% - 7px));
  }

  &::before {
    border-radius: 50%;
    border: 5px solid map-get($colors-border, orange);
    box-sizing: content-box;
    content: "";
    display: inline-block;
    height: 10px;
    margin-right: 6px;
    width: 10px;

    @include tablet {
      height: 14px;
      width: 14px;
    }
  }

  &::after {
    border-radius: 50%;
    border: 5px solid map-get($colors-border, orange);
    box-sizing: content-box;
    content: "";
    display: none;
    height: 10px;
    width: 10px;

    @include tablet {
      height: 14px;
      width: 14px;
    }
  }
}

.image {
  margin: 0 auto;
  max-width: 400px;
  border-radius: 50%;
  box-shadow: $panel-shadow;
  border: 10px solid white;
  order: 3;
  overflow: hidden;
  width: 60%;
  aspect-ratio: 1;

  @include tablet {
    width: 35%;
  }
}

// Handle alignment
.align-center {
  .words {
    color: map-get($colors-text, heading);
    font-weight: bold;
    font-size: map-get($font-sizes, xlarge);
    margin: 20px auto 0;
    max-width: 500px;
    text-align: center;
    width: 100%;
  }

  .timelinePoint {
    display: none;
  }

  .image {
    display: none;
  }
}

.align-right {
  .words {
    @include tablet {
      order: 3;
    }
  }

  .timelinePoint {
    transform: translateX(calc(16% - 14px));

    @include tablet {
      order: 2;
    }

    svg {
      transform: scaleX(-1);
    }
  }

  .pointName {
    @include tablet {
      transform: translateX(calc(-15%));
    }

    &::before {
      @include tablet {
        display: none;
      }
    }

    &::after {
      @include tablet {
        display: inline-block;
      }
    }
  }

  .image {
    @include tablet {
      order: 1;
    }
  }
}
