@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.HookInput {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 0 map-get($map: $margin, $key: xlarge);

  input {
    border: 1px solid map-get($map: $colors-border, $key: input);
    border-radius: map-get($map: $border-radius, $key: small);
    box-sizing: border-box;
    font-size: map-get($map: $font-sizes, $key: input);
    margin-top: map-get($map: $margin, $key: small);
    padding: map-get($map: $padding, $key: default);
    width: 100%;
  }
}

.HookInput.Error {
  label {
    color: map-get($map: $colors-interactive, $key: error);
  }

  input {
    border-color: map-get($map: $colors-interactive, $key: error);
  }
}

.ErrorText {
  color: map-get($map: $colors-interactive, $key: error);
  font-size: map-get($map: $font-sizes, $key: small);
  margin-top: map-get($map: $margin, $key: medium);
}
