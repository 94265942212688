@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.PageButton {
  button {
    border-color: transparent;
    border-radius: 100%;
    color: map-get($map: $colors-text, $key: primary);
    min-height: 36px;
    min-width: 36px;

    &:hover {
      border-color: transparent;
    }

    &.selected {
      background: $gray-50;
      font-weight: bold;
    }
  }
}

.ellipses {
  height: 29px;
  position: relative;
  width: 22px;

  &::after {
    border: 0;
    bottom: 0;
    box-shadow: none;
    color: map-get($map: $colors-text, $key: primary);
    content: "...";
    display: block;
    font-size: 22px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
  }
}
