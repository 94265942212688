@import "~css/colors";
@import "~css/layout";
@import "~css/transitions";
@import "~css/typography";

$dropdown-btn-transition-easing: map-get(
    $map: $transition-duration,
    $key: fast,
  )
  map-get(
    $map: $transition-easing,
    $key: easeinout,
  );

.Wrapper {
  display: inline-block;
  position: relative;
}

.DropdownMenu {
  background-color: map-get($map: $colors-background, $key: white);
  border: 1px solid map-get($map: $colors-border, $key: container);
  box-shadow: map-get($map: $colors-shadow, $key: panel);
  min-width: 150px;
  position: absolute;
  transition: opacity $dropdown-btn-transition-easing;
  z-index: map-get($map: $z-index, $key: dropdown);
}

.DropdownItem {
  color: map-get($map: $colors-text, $key: light);
  display: block;
  font-size: map-get($map: $font-sizes, $key: medium);
  padding: map-get($map: $padding, $key: large);
  width: 100%;

  &:hover {
    color: map-get($map: $colors-text, $key: default);
  }

  &:not(:last-child) {
    border-bottom: 1px solid map-get($map: $colors-border, $key: container);
  }
}
