@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.ContentWrapper {
  margin: map-get($map: $margin, $key: xlarge) $page-gutter-mobile;

  @include tablet {
    margin: map-get($map: $margin, $key: xlarge) auto;
    max-width: calc(100% - (#{$page-gutter-mobile} * 2));
  }

  @include desktop {
    margin: map-get($map: $margin, $key: xlarge) auto;
    max-width: calc(100% - (#{$page-gutter} * 2));
  }

  @include big_screen {
    max-width: 1000px;
  }
}
