@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.FormMessage {
  background-color: map-get($map: $colors-interactive, $key: success);
  border: 1px solid map-get($map: $colors-interactive, $key: success);
  border-radius: map-get($map: $border-radius, $key: small);
  color: map-get($map: $colors-text, $key: white);
  font-weight: 500;
  margin-bottom: map-get($map: $margin, $key: xlarge);
  padding: 0 map-get($map: $padding, $key: large);
  text-align: center;

  &.FormMessageError {
    background-color: map-get($map: $colors-interactive, $key: error);
    border: 1px solid map-get($map: $colors-interactive, $key: error);
  }
}
