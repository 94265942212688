@import "~css/colors";
@import "~css/layout";
@import "~css/media";

.AuthPage {
  background: map-get($map: $colors-background, $key: white);
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;

  @include desktop {
    grid-template-columns: 420px 1fr;
  }
}

.AuthPageVendor {
  grid-template-columns: 1fr;
}

.AuthContent {
  display: flex;
  flex-direction: column;
}

.AuthMain {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: map-get($map: $padding, $key: xxlarge);

  @include desktop {
    align-items: flex-start;
    margin-bottom: map-get($map: $margin, $key: xxlarge);
    padding-top: map-get($map: $padding, $key: xxlarge) * 3.5;
  }
}

.AuthFormContainer {
  max-width: 400px;
  width: 100%;
}

.AuthHeading {
  color: map-get($map: $colors-text, $key: black);
  font-size: 22px;
  margin-bottom: map-get($map: $margin, $key: xlarge);
  text-align: center;

  @include big_mobile {
    font-size: 28px;
    margin-bottom: map-get($map: $margin, $key: xxlarge);
  }

  @include desktop {
    text-align: left;
  }
}

.MobileAuthAccountQuestion {
  display: flex;
  flex: 1;
  text-align: center;

  @include desktop {
    display: none;
  }

  p {
    margin: 0 auto map-get($map: $margin, $key: default);
    text-align: center;
  }
}
