@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.Container {
  margin: 0 auto 100px;
  max-width: $page-max-width;
  padding: map-get($map: $padding, $key: xlarge)
    map-get($map: $padding, $key: large);

  @include mobile {
    display: grid;
    grid-template-columns: 1fr 320px 1fr;
    grid-template-rows: auto auto;
  }

  @include tablet {
    grid-template-columns: 1fr 760px 1fr;
  }

  @include desktop {
    grid-template-columns: 1fr 760px 1fr;
    grid-template-rows: auto auto;
  }

  @include big_screen {
    padding-left: $page-gutter;
    padding-right: $page-gutter;
  }
}

.BackButtonContainer {
  @include mobile {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  @include big_mobile {
    grid-column: 1 / 3;
  }

  @include desktop {
    justify-self: start;
  }

  .BackButtonLink {
    display: block;

    @include mobile {
      max-width: 220px;
    }
  }
}

.BackButton {
  align-items: center;
  display: flex;
  width: 100%;
}

.Content {
  margin: 30px auto;

  @include mobile {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }

  @include tablet {
    grid-column: 2 / 3;
  }

  .Header {
    margin: 0 auto;
    max-width: 640px;
    padding: 0 $page-gutter-mobile;

    h1.Title {
      @include responsive_font(32px, 40px);

      color: map-get($map: $colors-text, $key: black);
      text-align: center;
    }

    .Description {
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 48px;
    }
  }

  .ReferralFormContainer {
    padding: 0;
    width: 100%;
  }

  .Legal {
    margin-top: map-get($map: $margin, $key: default);
  }
}
