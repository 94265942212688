@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";

.SignUpWithEmail {
  margin-bottom: map-get($map: $margin, $key: xlarge);
}

.SignUpButton {
  width: 100%;
}

.FormRowNames {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}

.FirstName {
  margin-bottom: 0;
  margin-right: map-get($map: $margin, $key: default);
}

.LastName {
  margin-bottom: 0;
}
